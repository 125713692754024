import React, {useContext, useEffect, useState} from 'react';
import {fetchBestTeams, fetchTrendMatches} from "../../api";
import dayjs from "dayjs";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {AppContext} from "../../context/AppContext";
import padLock from "../../assets/icons/padlock.png";
import noFlagIcon from "../../assets/icons/noFlagIcon.png";

function TrendMatchesContent(props) {
    const {isPro, lang} = useContext(AppContext);

    const [trendMatchesData, setTrendMatchesData] = useState(null);
    useEffect(() => {
        fetchTrendMatches(props.type).then((data) => {
            setTrendMatchesData(data.data.data)
        })
    }, [])

    const tableHeader = [
        {
            en: [
                {text: 'League', value: 'league', align: 'start', sortable: false, width: '20%'},
                {text: 'Match', value: 'match', width: '20%', align: 'center'},
                {text: 'Home %', value: 'home', width: '10%', align: 'center'},
                {text: 'Away %', value: 'away', sortable: false, width: '10%', align: 'center'},
            ],
            tr: [
                {text: 'Lig', value: 'league', align: 'start', sortable: false, width: '20%'},
                {text: 'Maç', value: 'match', width: '20%', align: 'center'},
                {text: 'İç Saha %', value: 'home', width: '10%', align: 'center'},
                {text: 'Dış Saha %', value: 'away', sortable: false, width: '10%', align: 'center'},
            ]
        }
    ]

    const mobileTableHeader = [
        {
            en: [
                {text: 'League', value: 'leauge', align: 'start', sortable: false},
                {text: 'Home', value: 'home',  align: 'center'},
                {text: 'Away', value: 'away',  align: 'center'},
            ],
            tr: [
                {text: 'Lig', value: 'leauge', align: 'start', sortable: false},
                {text: 'İç Saha', value: 'home',  align: 'center'},
                {text: 'Dış Saha', value: 'away',  align: 'center'},
            ]
        }
    ]

    const statsColor = (stats) => {
        if (stats > 90) {
            return {
                background: '#72da2373',
                color: '#095035'
            }
        } else if (stats > 80) {
            return {
                background: '#72da233d',
                color: '#095035'
            }
        } else if (stats > 50) {
            return {
                background: '#f6f7cd',
                color: '#656500'
            }
        } else {
            return {
                background: '#ffe9e6',
                color: '#ff7563'
            }
        }
    }

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <div>
            {
                trendMatchesData !== null ?
                    <div>
                        {/*WEB VIEW*/}
                        <div className={'trend-matches-web-view'}>
                            <TableContainer>
                                <Table className="trend-matches-table-section" size={"medium"}
                                       sx={{minWidth: 250}}
                                       aria-label="simple table">
                                    <TableHead acitve={true}>
                                        <TableRow className="trend-matches-table-headers">
                                            {
                                                tableHeader[0][lang].map((header) => {
                                                    return (
                                                        <TableCell
                                                            sortDirection={header.text}> {header.text}</TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {trendMatchesData && trendMatchesData.map((teamStat, rowIndex) => {
                                        return (
                                            <TableBody className="table-body-section">
                                                {
                                                    rowIndex > 4 && !isPro ?
                                                        <TableRow
                                                            key={teamStat.id}
                                                            className="trend-matches-locked-row"
                                                            style={rowIndex % 2 ? {background: "#f3f3f3"} : {background: "white"}}
                                                        >
                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow
                                                            key={teamStat.id}
                                                            className="trend-matches-content-row"
                                                            style={rowIndex % 2 ? {background: "#f3f3f3"} : {background: "white"}}
                                                        >
                                                            <TableCell size="small" padding="normal" align="left">
                                                                <div className={'flag-area-section'}>
                                                                    <img className="country-flag"
                                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${teamStat.areaId}.png`}
                                                                         onError={(e) => onImageError(e)}
                                                                         alt="country"/>
                                                                    <div className="trend-matches-area-container">
                                                                        <span className="trend-matches-area-name">{teamStat.areaName} - </span>
                                                                        <span className="trend-matches-competition-name">{teamStat.competitionName}</span>
                                                                    </div>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div className="teams-name-container">
                                                                    <span className={'trend-matches-name trend-matches-home-team'}>{teamStat.homeTeam}</span>
                                                                    <span className={'trend-matches-name-line'}> - </span>
                                                                    <span className={'trend-matches-name'}>{teamStat.awayTeam}</span>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div className="percent-cell-container"
                                                                     style={statsColor(teamStat.homeRate)}>
                                                                    <span className="trend-matches-percent-text">{teamStat.homeRate} %</span>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div className="percent-cell-container"
                                                                     style={statsColor(teamStat.awayRate)}>
                                                                    <span className="trend-matches-percent-text">{teamStat.awayRate} %</span>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        )
                                    })
                                    }
                                </Table>
                            </TableContainer>
                        </div>

                        {/*MOBILE VIEW*/}
                        <div className={'trend-matches-mobile-view'}>
                            <TableContainer>
                                <Table className="trend-matches-table-section"
                                       aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="trend-matches-table-headers">
                                            {
                                                mobileTableHeader[0][lang].map((header) => {
                                                    return (
                                                        <TableCell
                                                            sortDirection={header.text}> {header.text}</TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {trendMatchesData && trendMatchesData.map((teamStat, rowIndex) => {
                                        return (
                                            <TableBody className="table-body-section">
                                                {
                                                    rowIndex > 4 && !isPro ?
                                                        <TableRow
                                                            key={teamStat.id}
                                                            className="best-team-locked-row"
                                                            style={rowIndex % 2 ? {background: "#f3f3f3"} : {background: "white"}}
                                                        >
                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <img className='pad-lock-icon' src={padLock} alt=""/>
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow
                                                            key={teamStat.id}
                                                            className="best-team-content-row"
                                                            style={rowIndex % 2 ? {background: "#f3f3f3"} : {background: "white"}}
                                                        >
                                                            <TableCell size="small" padding="normal" align="left">
                                                                <div>
                                                                    <div className={'flag-area-section'}>
                                                                        <img className="country-flag"
                                                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${teamStat.areaId}.png`}
                                                                             onError={(e) => onImageError(e)}
                                                                             alt="country"/>
                                                                        <div className="trend-matches-area-container">
                                                                            <span className="trend-matches-area-name">{teamStat.areaName} - </span>
                                                                            <span className="trend-matches-competition-name">{teamStat.competitionName}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="teams-name-container">
                                                                        <span className={'trend-matches-name'}>{teamStat.homeTeam} - {teamStat.awayTeam}</span>
                                                                    </div>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div className="percent-cell-container"
                                                                     style={statsColor(teamStat.homeRate)}>
                                                                    <span className="trend-matches-percent-text">{teamStat.homeRate} %</span>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell size="small" padding="normal" align="center">
                                                                <div className="percent-cell-container"
                                                                     style={statsColor(teamStat.awayRate)}>
                                                                    <span className="trend-matches-percent-text">{teamStat.awayRate} %</span>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        )
                                    })
                                    }
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    :
                    <div className="const_width loading-container">
                        <div>
                            <div className="loading blink"></div>
                            <div>
                                Loading...
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default TrendMatchesContent;
