import React from 'react';
import Out from '../../../assets/icons/out.png'
import In from '../../../assets/icons/in.png'
import Ball from '../../../assets/icons/ball.png'
//import FvLmtV2Container from "../../fv-lmt-v2/views/FVLmtV2Container";
import noTeamLogo from "../../../assets/icons/noTeamLogo.png";
import lmtPlayClick from "../../../assets/icons/lmt-play-img.png";
import yCard from "../../../assets/icons/mackolik-ycard.gif";
import rCard from "../../../assets/icons/mackolik-rcard.gif";
import yrCard from "../../../assets/icons/mackolik-yrcard.gif";
import penGoal from "../../../assets/icons/penalty_goal.gif";
import ownGoal from "../../../assets/icons/own-goal.gif";

function MatchInfo(props) {

    const {matchTeamsDetail, timeLineData, lang} = props;

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    return (
        <div>
            {/*<div style={{marginTop: '10px'}}>
                <FvLmtV2Container></FvLmtV2Container>
            </div>*/}
            {/*<img className='lmt-play-lick-img' src={lmtPlayClick} alt=""/>*/}
            <div className='lmt-title-container'>
                <span>Canlı Görsel Anlatım</span>
            </div>
            <div className='lmt-play-lick-img'></div>
            <div>
                <div className='time-line-and-teams-section'>
                    {
                        matchTeamsDetail &&
                        <div className='timeline-teams-container'>
                            <div className='match-detail-home-team-events-container'>
                                {matchTeamsDetail.home}
                                <img className="match-detail-timeline-teams-logo"
                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.homeId}.png`}
                                     onError={(e) => onImageError(e)}
                                     alt=""/>
                            </div>

                            <div className='event-time-container'>

                            </div>

                            <div className='match-detail-away-team-events-container'>
                                <img className="match-detail-timeline-teams-logo match-detail-timeline-teams-logo-away"
                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.awayId}.png`}
                                     onError={(e) => onImageError(e)}
                                     alt=""/>
                                {matchTeamsDetail.away}
                            </div>
                        </div>
                    }
                </div>

                <div>
                    {
                        timeLineData &&
                        timeLineData.map((event, index) => {
                            return (
                                <div>
                                    {/*YELLOW CARD*/}
                                    {
                                        event.type === 'YellowCard' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='home-event-player-name'>{event.playerName}</span>
                                                        <img src={yCard} alt=""/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img src={yCard} alt=""/>
                                                        <span className='away-event-player-name'>{event.playerName}</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*RED CARD*/}
                                    {
                                        event.type === 'RedCard' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='home-event-player-name'>{event.playerName}</span>
                                                        <img src={rCard} alt=""/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img src={rCard} alt=""/>
                                                        <span className='away-event-player-name'>{event.playerName}</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*VAR*/}
                                    {
                                        event.type === 'V_A_R' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='home-event-player-name'>{event.playerName} V</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <span className='away-event-player-name'> V {event.playerName}</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*SUBSTITUTION*/}
                                    {
                                        event.type === 'Substitution' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='away-event-player-name'>{event.playerOutName}</span>
                                                        <img className='timeline-icons' src={Out} alt="Out"/>

                                                        <span className='away-event-player-name'>{event.playerInName}</span>
                                                        <img className='timeline-icons' src={In} alt="In"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <div className='away-events-container'>
                                                            <img className='timeline-icons' src={In} alt="In"/>
                                                            <span className='home-event-player-name'>{event.playerInName}</span>

                                                            <img className='timeline-icons' src={Out} alt="Out"/>
                                                            <span className='home-event-player-name'>{event.playerOutName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*SUBSTITUTION INJURED*/}
                                    {
                                        event.type === 'Substitution_Injured' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        <span className='away-event-player-name'>{event.playerOutName}</span>
                                                        <img className='timeline-icons' src={Out} alt="Out"/>

                                                        <span className='away-event-player-name'>{event.playerInName}</span>
                                                        <img className='timeline-icons' src={In} alt="In"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <div className='away-events-container'>
                                                            <img className='timeline-icons' src={In} alt="In"/>
                                                            <span className='home-event-player-name'>{event.playerInName}</span>

                                                            <img className='timeline-icons' src={Out} alt="Out"/>
                                                            <span className='home-event-player-name'>{event.playerOutName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*GOAL*/}
                                    {
                                        event.type === 'Goal' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        {
                                                            event.playerAssistName &&
                                                            <a className='home-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <a className='home-event-goal-player-name'>{event.playerName}</a>
                                                        <img className='timeline-icons' src={Ball} alt="Goal"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img className='timeline-icons' src={Ball} alt="Goal"/>
                                                        <a className='away-event-goal-player-name'>{event.playerName}</a>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='away-assist-player-name'>({event.playerAssistName})</a>
                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*GOAL PENALTY*/}
                                    {
                                        event.type === 'Goal_Penalty' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        {
                                                            event.playerAssistName &&
                                                            <a className='home-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <a className='home-event-goal-player-name'>{event.playerName}</a>
                                                        <img className='pen-goal-icon' src={penGoal} alt="Goal Penalty"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img className='pen-goal-icon' src={penGoal} alt="Goal Penalty"/>
                                                        <a className='away-event-goal-player-name'>{event.playerName}</a>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='away-assist-player-name'>({event.playerAssistName})</a>
                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }

                                    {/*GOAL OWN*/}
                                    {
                                        event.type === 'Goal_Own' &&
                                        <div className={index % 2 === 1 ? 'timeline-event-container' : 'timeline-event-container-stripped'}>
                                            {
                                                event.isHome ?
                                                    <div className='home-events-container'>
                                                        {
                                                            event.playerAssistName &&
                                                            <a className='home-assist-player-name'>({event.playerAssistName})</a>
                                                        }

                                                        <a className='home-event-goal-player-name'>{event.playerName}</a>
                                                        <img className='timeline-icons' src={ownGoal} alt="Own Goal"/>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div></div>

                                            <div className='event-time-container'>
                                                <span className='match-detail-event-minute'>{event.minute}'</span>
                                            </div>

                                            <div></div>

                                            {
                                                !event.isHome ?
                                                    <div className='away-events-container'>
                                                        <img className='timeline-icons' src={ownGoal} alt="Own Goal"/>
                                                        <a className='away-event-goal-player-name'>{event.playerName}</a>

                                                        {
                                                            event.playerAssistName &&
                                                            <a className='away-assist-player-name'>({event.playerAssistName})</a>
                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default MatchInfo;
