import React from 'react';

function StatsRowNoInfo(props) {
  return (
    <td className={props.borderStyle}>
      <div className={'no-info-stats-row'}>No Information
      </div>
    </td>
  );
}

export default StatsRowNoInfo;
