import React from 'react';
import {colorTheme} from "../ColorCss";

function LiveScoresTableHeader(props) {
    const statStyleWidth = () => {
        if (props.stHeader.width === 'small') {
            return 'live-scores-stats-header-small'
        } else {
            return 'live-scores-stats-header-other'
        }
    }

    return (
        <td className={statStyleWidth()}>
            <div className='live-scores-table-header-container'>
                <span style={{color: colorTheme.dark444}} className="live-scores-table-st-header">{props.stHeader.text}</span>
            </div>
        </td>
    );
}

export default LiveScoresTableHeader;
