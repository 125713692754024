import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import './Payment.css'
import checkImg from '../../assets/icons/check.png'
import waitImg from '../../assets/icons/waiting.png'

function PaymentSuccess(props) {

    const userToken = localStorage.getItem('user-token');
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams()

    console.log('params search', searchParams.get('session_id'));
    const sessionId = searchParams.get('session_id');
    const userId = searchParams.get('userId');

    useEffect(() => {
        const requestOptionsSuccessWithoutToken = {
            method: 'POST',
            headers: {'x-platform': 'XFStats',},
        };
        const requestOptionsSuccess = {
            method: 'POST',
            headers: {'Authorization': `Bearer ${userToken}`, 'x-platform': 'XFStats',},
        };

        const paymentSuccess = async () => {
            if (!userToken) {
                await fetch(`https://santra24.6stats.com/api/v1/payment/finalize-payment/userId?session_id=${sessionId}&userId=${userId}`, requestOptionsSuccessWithoutToken)
                    .then(async (response) => {
                        const responseObject = await response.json();
                        console.log('success Payment', responseObject);
                        if (responseObject) {
                            setLoading(false);
                            setInterval(() => {
                                window.location.href = '/sign-in'
                            }, 2500);
                        }
                    })
                    .catch((err) => err);
            } else {
                await fetch(`https://santra24.6stats.com/api/v1/payment/finalize-payment?session_id=${sessionId}`, requestOptionsSuccess)
                    .then(async (response) => {
                        const responseObject = await response.json();
                        console.log('success Payment', responseObject);
                        if (responseObject) {
                            localStorage.removeItem('user');
                            setLoading(false);

                            const detailPayload = {
                                method: 'GET',
                                headers: {'Authorization': `Bearer ${userToken}`},
                            }
                            const userDetail = await fetch('https://santra24.6stats.com/api/user/me', detailPayload);
                            const user = await userDetail.json();
                            console.log('success user me ', user)
                            if (user.success === true) {
                                localStorage.setItem('user', JSON.stringify(user.data));
                                setInterval(() => {
                                    window.location.href = '/'
                                }, 2500)
                            }
                        }
                    })
                    .catch((err) => err);
            }
        }

        paymentSuccess().then(r => r);

    }, [])

    return (
        <div className='const-width'>
            <div className='payment-section'>
                {
                    loading ?
                        <div className='wait-container'>
                            <img className='success-check-icon' src={waitImg} alt="Success"/>
                            <span className='success-text'>Please Wait...</span>
                        </div>
                        :
                        <div className='success-message-container'>
                            <div className='success-text-and-img-container'>
                                <img className='success-check-icon' src={checkImg} alt="Success"/>
                                <span className='success-text'>Congratulations, your PRO membership has been successfully completed.</span>
                            </div>

                            <div className='go-home-page-button-container'>
                                <Link className='go-home-page-button' to={'/'}>Go Home Page</Link>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

export default PaymentSuccess;
