import React from 'react';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {styled} from "@mui/material";
import Box from "@mui/material/Box";

function MatchDetailsStatistics(props) {

    const {statisticData, lang} = props;


    const BorderLinearProgressHome = styled(LinearProgress)(({theme}) => ({
        height: 10,
        borderRadius: 1,
        width: 50,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 20 : 80],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 1,
            backgroundColor: theme.palette.mode === 'light' ? '#3069b0' : '#b4c9e1',
        },
    }));

    const BorderLinearProgressAway = styled(LinearProgress)(({theme}) => ({
        height: 10,
        borderRadius: 1,
        width: 50,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#f8d1af',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 1,
            backgroundColor: theme.palette.mode === 'light' ? '#ea7814' : '#f8d1af',
        },
    }));

    const statsProgressBar = (name, val) => {
        if (name === 'Attacks' && val > 100) {
            return 90
        } else {
            return val
        }
    }

    const statsLists = [
        {
            en: {
                'Ball possession': 'Ball possession',
                'Dangerous Attacks': 'Dangerous Attacks',
                'Attacks': 'Attacks',
                'Total shots': 'Total shots',
                'Shots on target': 'Shots on target',
                'Shots off target': 'Shots off target',
                'Blocked shots': 'Blocked shots',
                'Corner kicks': 'Corner kicks',
                'Yellow cards': 'Yellow cards',
                'Red cards': 'Red cards',
                'Fouls': 'Fouls',
                'Offsides': 'Offsides',
                'Shots inside box': 'Shots inside box',
                'Shots outside box': 'Shots outside box',
                'Hit woodwork': 'Hit woodwork',
                'Passes': 'Passes',
                'Accurate passes': 'Accurate passes',
                'Crosses': 'Crosses',
                'Duels won': 'Duels won',
                'Defending': 'Defending',
                'Goalkeeper saves': 'Goalkeeper saves',
                'Interceptions': 'Interceptions',
                'Tackles': 'Tackles',
            },
            tr: {
                'Ball possession': 'Topla Oynama',
                'Dangerous Attacks': 'Tehlikeli Atak',
                'Attacks': 'Atak',
                'Total shots': 'Toplam Şut',
                'Shots on target': 'İsabetli Şut',
                'Shots off target': 'İsabetsiz Şut',
                'Blocked shots': 'Şut Engelleme',
                'Corner kicks': 'Korner',
                'Yellow cards': 'Sarı Kart',
                'Red cards': 'Kırmızı Kart',
                'Fouls': 'Faul',
                'Offsides': 'Ofsayt',
                'Shots inside box': 'Ceza Sahası İçinden Şut',
                'Shots outside box': 'Ceza Sahası Dışından Şut',
                'Hit woodwork': 'Hit woodwork',
                'Passes': 'Pas',
                'Accurate passes': 'İsabetli Pas',
                'Crosses': 'Orta',
                'Duels won': 'Kazanılan İkili Mücadele',
                'Defending': 'Defending',
                'Goalkeeper saves': 'Kaleci Kurtarışı',
                'Interceptions': 'Pas Arası',
                'Tackles': 'Top Kapma',
            }
        }
    ];

    const statsTitleLists = [
        {
            en: {
                'Main Stats': 'Main Stats',
                'Extra': 'Extra',
                'Passes': 'Passes',
                'Duels': 'Duels',
                'Defending': 'Defending',
                'Extra Stats': 'Extra Stats',
            },
            tr: {
                'Main Stats': 'Genel',
                'Extra': 'Ekstra',
                'Passes': 'Paslar',
                'Duels': 'İkili Mücadele',
                'Defending': 'Defans',
                'Extra Stats': 'Ekstra İstatistik',
            }
        }
    ];

    return (
        <div className='match-detail-statistics-section'>
            {
                statisticData &&
                <div>
                    {
                        lang === 'tr' ?
                            <div className='statistics-and-standings-title'>İstatistikler</div>
                            :
                            <div className='statistics-and-standings-title'>Statistics</div>
                    }
                </div>
            }
            {
                statisticData &&
                statisticData.map(stat => {
                    return (
                        <div>
                            <div className='statistic-sub-title'>
                                {statsTitleLists[0][lang][stat.name]}
                            </div>
                            {
                                stat.stats.map((st, index) => {
                                    return (
                                        <div
                                            className={index % 2 === 0 ? 'stats-and-progress-container' : 'stats-and-progress-container-stripped'}>
                                            <div style={{display: "flex"}}>
                                                <Box sx={{flexGrow: 1, scale: "-1 1"}}>
                                                    <BorderLinearProgressHome variant="determinate" value={statsProgressBar(st.name, st.home)}/>
                                                </Box>
                                            </div>
                                            <div className='match-detail-percent-value'>
                                                {st.home}
                                            </div>
                                            <div className='statistic-name'>
                                                <span>{statsLists[0][lang][st.name]}</span>
                                            </div>
                                            <div className='match-detail-percent-value'>
                                                {st.away}
                                            </div>
                                            <div>
                                                <Box sx={{flexGrow: 1}}>
                                                    <BorderLinearProgressAway variant="determinate" value={statsProgressBar(st.name, st.away)}/>
                                                </Box>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

export default MatchDetailsStatistics;
