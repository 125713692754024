import React from 'react';

function MatchDetailsStandings(props) {

    const {statisticData, matchDetailData, matchTeamsDetail, standingsData, lang} = props;

    const teamNameStyle = (teamId) => {
        if (teamId === matchTeamsDetail.homeId) {
            return {
                color: '#1a90ff',
                fontWeight: '600'
            }
        } else if (teamId === matchTeamsDetail.awayId) {
            return {
                color: '#ff9800',
                fontWeight: '600'
            }
        }
    }

    const bgStyle = (dt, team) => {
        if (dt.homeId === team.teamId || dt.awayId === team.teamId) {
            return '#fff3a5'
        }
    }

    return (
        <div className={statisticData ? 'standings-section-with-margin' : 'standings-section-without-margin'}>
            {
                standingsData &&
                <div>
                    <div className='match-detail-standings-league-container'>
                        <img className="match-detail-standings-league-flag"
                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${matchDetailData?.areaId}.png`}
                             alt=""/>
                        <a className='match-detail-standings-league-name'>{matchDetailData?.league}</a>
                    </div>
                    <div>
                        {
                            standingsData.map(items => {
                                return (
                                    <div>
                                        {
                                            items.name && <div className='standings-group-name'>{items.name}</div>
                                        }

                                        <div>
                                            {
                                                lang === 'tr' ?
                                                    <div className='standings-header-row-container standings-titles'>
                                                        <div></div>
                                                        <div></div>
                                                        <div>O</div>
                                                        <div>G</div>
                                                        <div>B</div>
                                                        <div>M</div>
                                                        <div>P</div>
                                                    </div>
                                                    :
                                                    <div className='standings-header-row-container standings-titles'>
                                                        <div></div>
                                                        <div></div>
                                                        <div>MP</div>
                                                        <div>W</div>
                                                        <div>D</div>
                                                        <div>L</div>
                                                        <div>PTS</div>

                                                    </div>
                                            }
                                            {
                                                items.rows.map((team, index) => {
                                                    return (
                                                        <div style={{background: bgStyle(matchTeamsDetail, team)}}
                                                             className={index % 2 === 0 ? 'standings-header' : 'standings-header-stripe-row'}>
                                                            <div className='match-detail-standings-position'>
                                                                {team.position}
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    window.open(`/team-detail/${team.teamId}`)
                                                                }}
                                                                style={teamNameStyle(team.teamId)}
                                                                className='standings-team-name-container'>
                                                                <a className='standings-team-name-text'>{team.teamName}</a>
                                                            </div>

                                                            <div>
                                                                {team.played}
                                                            </div>
                                                            <div>
                                                                {team.win}
                                                            </div>
                                                            <div>
                                                                {team.draw}
                                                            </div>
                                                            <div>
                                                                {team.loss}
                                                            </div>
                                                            {/*<div style={{fontSize: '10px'}}>
                                                        {team.goalsFor} : {team.goalsAgainst}
                                                    </div>
                                                    <div>
                                                        {team.goalsDiff}
                                                    </div>*/}
                                                            <div className='match-detail-standings-points'>
                                                                {team.points}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default MatchDetailsStandings;
