import React, {useContext, useState} from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {AppContext} from "../../context/AppContext";

function SignUp(props) {

    const {register, lang} = useContext(AppContext);

    const [input, setInput] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [error, setError] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    })

    const onInputChange = e => {
        const {name, value} = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }

    const validateInput = e => {
        let {name, value} = e.target;
        setError(prev => {
            const stateObj = {...prev, [name]: ""};

            switch (name) {
                case "name":
                    if (!value) {
                        if (lang === 'tr') {
                            stateObj[name] = "Lütfen isminizi giriniz.";
                        } else {
                            stateObj[name] = "Please enter Name.";
                        }
                    }
                    break;

                case "username":
                    if (!value) {
                        if (lang === 'tr') {
                            stateObj[name] = "Lütfen kullanıcı adınızı giriniz.";
                        } else {
                            stateObj[name] = "Please enter User Name.";
                        }
                    }
                    break;

                case "email":
                    if (!value) {
                        if (lang === 'tr') {
                            stateObj[name] = "Lütfen Mail adresinizi giriniz.";
                        } else {
                            stateObj[name] = "Please enter Email.";
                        }
                    }
                    break;

                case "password":
                    if (!value) {
                        if (lang === 'tr') {
                            stateObj[name] = "Lütfen şifrenizi giriniz.";
                        } else {
                            stateObj[name] = "Please enter Password.";
                        }
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        if (lang === 'tr') {
                            stateObj["confirmPassword"] = "Şifre ve şifre onaylama eşleşmiyor.";
                        } else {
                            stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
                        }
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    break;

                case "confirmPassword":
                    if (!value) {
                        if (lang === 'tr') {
                            stateObj[name] = "Lütfen şifrenizi doğrulayınız.";
                        } else {
                            stateObj[name] = "Please enter Confirm Password.";
                        }
                    } else if (input.password && value !== input.password) {
                        if (lang === 'tr') {
                            stateObj[name] = "Şifreler uyuşmuyor.";
                        } else {
                            stateObj[name] = "Passwords doesn't match.";
                        }
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }

    return (
        <div className={'general-auth-section'}>
            <Container component="main" maxWidth="sm">
                <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        py: 6,
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5">
                        <div>
                            {
                                lang === 'tr' ?
                                    <span>Kayıt Ol</span>
                                    :
                                    <span>Sign Up</span>
                            }
                        </div>
                    </Typography>
                    <Box component="form" onSubmit={register} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="name"
                            label={lang === 'tr' ? 'Ad Soyad' : 'Name Surname'}
                            id="name"
                            autoComplete="name"
                            autoFocus
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.name && <span className='register-error-text'>{error.name}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="username"
                            label={lang === 'tr' ? 'Kullanıcı Adı' : 'User Name'}
                            id="username"
                            autoComplete="username"
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.username && <span className='register-error-text'>{error.username}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={lang === 'tr' ? 'Email Adresi' : 'Email Address'}
                            name="email"
                            autoComplete="email"
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.email && <span className='register-error-text'>{error.email}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={lang === 'tr' ? 'Şifre' : 'Password'}
                            type="password"
                            id="password"
                            value={input.password}
                            autoComplete="current-password"
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.password && <span className='register-error-text'>{error.password}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label={lang === 'tr' ? 'Şifreyi Onayla' : 'Confirm Password'}
                            type="password"
                            value={input.confirmPassword}
                            id="confirmPassword"
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.confirmPassword && <span className='register-error-text'>{error.confirmPassword}</span>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            style={{background: '#1a3150'}}
                        >
                            {
                                lang === 'tr' ?
                                    <span>Kayıt Ol</span>
                                :
                                    <span>Sign Up</span>
                            }
                        </Button>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

export default SignUp;
