import React, {useContext, useEffect, useState} from 'react';
import {fetchLeagueStandings} from "../../../api";
import LeagueStandingsTableAll from "./LeagueStandingsTableAll";
import LeagueStandingsTableHome from "./LeagueStandingsTableHome";
import LeagueStandingsTableAway from "./LeagueStandingsTableAway";
import {AppContext} from "../../../context/AppContext";

function LeagueStandings(props) {
    const {lang} = useContext(AppContext);

    const {leagueStandingsData} = props;

    const [toggle, setToggle] = useState(0);

    const standingsTableButtons = [
        {
            en: [
                {
                    buttonName: 'General',
                    toggle: 0
                },
                {
                    buttonName: 'Home',
                    toggle: 1
                },
                {
                    buttonName: 'Away',
                    toggle: 2
                }
            ],
            tr: [
                {
                    buttonName: 'Genel',
                    toggle: 0
                },
                {
                    buttonName: 'İç Saha',
                    toggle: 1
                },
                {
                    buttonName: 'Dış Saha',
                    toggle: 2
                }
            ]
        }
    ]

    const activeTableButton = (btn) => {
        if (btn.toggle === toggle) {
            return 'standings-active-button'
        } else {
            return 'standings-button'
        }
    }

    const onClickTableButton = (btn) => {
        setToggle(btn.toggle)
    }

    return (
        <div className={'league-standings-table-section'}>
            <div className={'standings-table-button-container'}>
                {
                    standingsTableButtons[0][lang].map(btn => {
                        return (
                            <div
                                key={btn.toggle}
                                onClick={() => onClickTableButton(btn)}
                                className={activeTableButton(btn)}
                            >
                                {btn.buttonName}
                            </div>
                        )
                    })
                }
            </div>

            {
                toggle === 0 && <LeagueStandingsTableAll lang={lang} leagueStandingsData={leagueStandingsData}/>
            }
            {
                toggle === 1 && <LeagueStandingsTableHome lang={lang} leagueStandingsData={leagueStandingsData}/>
            }
            {
                toggle === 2 && <LeagueStandingsTableAway lang={lang} leagueStandingsData={leagueStandingsData}/>
            }
        </div>
    );
}

export default LeagueStandings;
