import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {fetchTeamStandings, fetchTeamTeamDetail} from "../../api";
import './TeamDetails.css'
import {Tab, Tabs} from "@mui/material";
import TeamStandings from "./standings/TeamStandings";
import Results from "../league-details/results/Results";
import TeamFixtures from "./fixtures/TeamFixtures";
import TeamResults from "./results/TeamResults";
import noTeamLogo from "../../assets/icons/noTeamLogo.png";
import {AppContext} from "../../context/AppContext";
import TeamInfo from "./team-info/TeamInfo";
import DocumentMeta from 'react-document-meta';
import {teamDetailsMeta} from "../../SEO/SeoMetaTags";

function TeamDetails(props) {
    const {lang} = useContext(AppContext);

    const params = useParams();
    const [teamDetailsData, setTeamDetailsData] = useState();
    const [teamStandingsData, setTeamStandingsData] = useState();
    const [side, setSide] = useState('all');
    const [toggle, setToggle] = useState(0);
    const [toggleBottomTabs, setToggleBottomTabs] = useState(0);

    useEffect(() => {
        fetchTeamTeamDetail(params.id).then(data => {
            setTeamDetailsData(data.data.data);
        })

        fetchTeamStandings(params.id).then(data => {
            setTeamStandingsData(data.data.data.groups)
        })
    }, []);

    const teamDetailTabs = [
        {
            en: [
                {
                    tabsName: 'Results',
                    type: 'Results',
                    toggle: 0
                },
                {
                    tabsName: 'Fixtures',
                    type: 'Fixtures',
                    toggle: 1
                },
                {
                    tabsName: 'Statistics',
                    type: 'Statistics',
                    toggle: 2,
                    disable: true,
                }
            ],
            tr: [
                {
                    tabsName: 'Sonuçlar',
                    type: 'Results',
                    toggle: 0
                },
                {
                    tabsName: 'Fikstür',
                    type: 'Fixtures',
                    toggle: 1
                },
                {
                    tabsName: 'İstatistikler',
                    type: 'Statistics',
                    toggle: 2,
                    disable: true,
                }
            ]
        }
    ]

    const teamDetailsBottomTabs = [
        {
            en: [
                {
                    tabsName: 'By League',
                    type: 'ByLeague',
                    toggle: 0
                },
                {
                    tabsName: 'By Time',
                    type: 'ByTime',
                    toggle: 1
                },
            ],
            tr: [
                {
                    tabsName: 'Lige Göre',
                    type: 'ByLeague',
                    toggle: 0
                },
                {
                    tabsName: 'Tarihe Göre',
                    type: 'ByTime',
                    toggle: 1
                }
            ]
        }
    ]

    const clickTab = (event) => {
        setToggle(event.toggle)
    }
    const clickBottomTab = (event) => {
        setToggleBottomTabs(event.toggle)
    }

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    const sideMatches = [
        {text: 'All Matches', side: 'all'},
        {text: 'Home', side: 'home'},
        {text: 'Away', side: 'away'},
    ]

    const setMatchesSide = (e) => {
        setSide(e.target.value);
        console.log('e', e.target.value);
    }

    return (
        <DocumentMeta {...teamDetailsMeta(teamDetailsData?.longname, teamDetailsData?.id)[lang]}>
            <div className='team-details-general-section const-width-content'>
                {/*{
                teamDetailsData &&
                <div className='team-details-general-container'>
                    <div className='team-logo-and-name-container'>
                        <img className="team-detail-team-logo"
                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${teamDetailsData.id}.png`}
                             onError={(e) => onImageError(e)}
                             alt=""/>
                        <span className='team-detail-team-name'>{teamDetailsData.name}</span>
                    </div>
                </div>
            }*/}

                <div className='team-info-section'>
                    <TeamInfo teamDetailsData={teamDetailsData}></TeamInfo>
                    <div className='team-detail-empty-section'></div>
                </div>


                <div className='team-details-components-container'>
                    <div className='team-detail-and-tabs-section'>
                        <div className='team-detail-tab-container'>
                            <Tabs variant={"scrollable"}
                                  TabIndicatorProps={{style: {display: "none"}}} value={toggle}>
                                {
                                    teamDetailTabs[0][lang].map(tabs => {
                                        return (
                                            <Tab disabled={tabs?.disable} className='team-detail-tab'
                                                 label={tabs.tabsName}
                                                 onClick={() => clickTab(tabs)}></Tab>
                                        )
                                    })
                                }
                            </Tabs>
                        </div>
                        <div className='team-detail-bottom-tab-container'>
                            <div style={{flex: 1}}>
                                <Tabs variant={"scrollable"}
                                      TabIndicatorProps={{style: {display: "none"}}} value={toggleBottomTabs}>
                                    {
                                        teamDetailsBottomTabs[0][lang].map(tabs => {
                                            return (
                                                <Tab className='team-detail-bottom-tab' label={tabs.tabsName}
                                                     onClick={() => clickBottomTab(tabs)}></Tab>
                                            )
                                        })
                                    }
                                </Tabs>
                            </div>

                            <div style={{display: 'flex', placeItems: 'center', marginRight: '10px'}}>
                                <select onChange={(e) => setMatchesSide(e)} id="cboMatchSide"
                                        style={{fontSize: '13px'}}>
                                    {sideMatches.map((option) => {
                                        return (
                                            <option key={option.side} value={option.side}>{option.text}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        {toggle === 0 && <TeamResults side={side} teamDetailsData={teamDetailsData}
                                                      toggleBottomTabs={toggleBottomTabs} lang={lang}/>}
                        {toggle === 1 && <TeamFixtures side={side} teamDetailsData={teamDetailsData}
                                                       toggleBottomTabs={toggleBottomTabs} lang={lang}/>}
                    </div>

                    <div className='team-detail-right-column-section'>
                        <TeamStandings lang={lang} teamStandingsData={teamStandingsData}/>
                    </div>
                </div>
            </div>
        </DocumentMeta>
    );
}

export default TeamDetails;
