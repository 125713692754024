import React, {useContext, useEffect, useState} from 'react';
import livePng from '../../assets/icons/live-icon.png'
import dayjs from "dayjs";
import './Stats.css'
import '../../App.css'
import {fetchFavoritesMatches, fetchFixtures, fetchLiteMatches} from "../../api";
import LeagueAndMatchesTable from "./LeagueAndMatchesTable";
import {AppContext} from "../../context/AppContext";
import DocumentMeta from "react-document-meta";
import {statsMeta} from "../../SEO/SeoMetaTags";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useNavigate} from "react-router-dom";

const percentList = [
    {
        en: [
            {text: 'All', value: 0},
            {text: '> 80', value: 80},
            {text: '> 70', value: 70},
            {text: '> 60', value: 60},
            {text: '> 50', value: 50},
            {text: '> 25', value: 25},
        ],
        tr: [
            {text: 'Hepsi', value: 0},
            {text: '> 80', value: 80},
            {text: '> 70', value: 70},
            {text: '> 60', value: 60},
            {text: '> 50', value: 50},
            {text: '> 25', value: 25},
        ]
    }
]

const statsList = [
    {name: 'goalStats'},
    {name: 'cornerStats'},
    {name: 'cardStats'},
]

function Stats(props) {
    const {favMatches, lang, isPro} = useContext(AppContext);
    const navigate = useNavigate();

    const [liveInstantMatches, setLiveInstantMatches] = useState(null);
    const [fixturesData, setFixturesData] = useState(null);
    const [liveData, setLiveData] = useState(null);
    const [fetchFavMatches, setFetchFavMatches] = useState([]);
    const [live, setLive] = useState(false);
    const [toggles, setToggle] = useState(0);
    const [statsType, setStatsType] = useState('goalStats');
    const [currentStats, setCurrentStats] = useState('goalStatsHeader');
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().substr(0, 10));
    const [selectedPercent, setSelectedPercent] = useState(0);
    const [statsButtonsEN, setStatsButtons] = useState([
        {text: 'Goal', type: 'goalStats', header: 'goalStatsHeader', toggle: 0},
        {text: 'Corner', type: 'cornerStats', header: 'cornerStatsHeader', toggle: 1},
        {text: 'Cards', type: 'cardStats', header: 'cardsStatsHeader', toggle: 2},
        {text: 'AVG', type: 'avgStats', header: 'avgStatsHeader', toggle: 3},
        {text: 'Referee', type: 'refereeStats', header: 'refereeStatsHeader', toggle: 4},
    ]);
    const [statsButtonsTR, setStatsButtonsTR] = useState([
        {text: 'Gol', type: 'goalStats', header: 'goalStatsHeader', toggle: 0},
        {text: 'Korner', type: 'cornerStats', header: 'cornerStatsHeader', toggle: 1},
        {text: 'Kart', type: 'cardStats', header: 'cardsStatsHeader', toggle: 2},
        {text: 'AVG', type: 'avgStats', header: 'avgStatsHeader', toggle: 3},
        {text: 'Hakem', type: 'refereeStats', header: 'refereeStatsHeader', toggle: 4},
    ]);
    const [week, setWeek] = useState([
        {day: new Date().toISOString().substr(0, 10),},
        {day: dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(2, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(3, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(4, 'days').format('YYYY-MM-DD')},
    ]);

    /*Stats CODES*/
    useEffect(() => {
        fetchFixtures(currentDate).then((data) => {
            const fixtureData = data.data.data;
            setFixturesData(fixtureData);
            setLiveData(fixtureData);
        })
    }, [currentDate]);

    const fixtureMatches = [];
    const liveLeaguesIndex = {};
    if (fixturesData) {
        fixturesData.forEach((leagues, index) => {
            const {leagueId, league, areaId, area, seasonId} = leagues;
            liveLeaguesIndex[leagueId] = index;

            const matchesWithAllSelectedVal = leagues.matches.filter(match => {
                /*return statsList.every((stat) => {
                    return match[stat.name]?.every((item) => item.val.slice(0, -1) >= selectedPercent);
                });*/
                if (match[statsType]) {
                    return match[statsType]?.every((item) => item.val.slice(0, -1) >= selectedPercent);
                } else {
                    if (selectedPercent === 0) {
                        return match
                    }
                }
            });
            matchesWithAllSelectedVal.forEach(match => {
                fixtureMatches.push({
                    leagueDetail: { leagueId, league, areaId, area, seasonId },
                    match
                });
            })
        })
    }

    let filteredInstantLiveMatches = [];
    if (liveInstantMatches) {
        /*const liveInstant = liveInstantMatches.filter(item => item.status === 'Playing');*/
        filteredInstantLiveMatches = fixtureMatches.map(matches => {
            const findLiveInstant = liveInstantMatches.find(match => match.id === matches.match?.id)
            return {
                ...matches,
                findLiveInstant
            }
        })
    }

    const matchesByLeagues = {};
    const leagueDetails = {};
    filteredInstantLiveMatches.forEach(liveMatch => {
        const {leagueDetail, match, findLiveInstant} = liveMatch;
        const asd = {...match, ...findLiveInstant};

        leagueDetails[leagueDetail.leagueId] = leagueDetail;

        const matches = matchesByLeagues[leagueDetail.leagueId] || [];
        matchesByLeagues[leagueDetail.leagueId] = [...matches, asd];
    })

    const sections = Object.entries(matchesByLeagues).map(entry => {
        const [leagueId, matches] = entry;
        return {
            title: leagueDetails[leagueId],
            data: matches
        }
    }).sort(
        (a, b) => liveLeaguesIndex[a.title.leagueId] - liveLeaguesIndex[b.title.leagueId],
    );

    /*FAV MATCHES CODES*/
    useEffect(() => {
        if (favMatches.length > 0) {
            const matchesId = favMatches.map(item => item.match.id);

            fetchFavoritesMatches(matchesId).then(data => {
                const fetchMatches = data.data.data;
                setFetchFavMatches(fetchMatches);
            });

            const interval = setInterval(() => {
                fetchFavoritesMatches(matchesId).then(data => {
                    const fetchMatches = data.data.data;
                    setFetchFavMatches(fetchMatches);
                });
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [favMatches]);

    const favMatchesByLeagues = {};
    const favLeagueDetails = {};

    favMatches.forEach(favMatch => {
        const {leagueDetail, match} = favMatch;
        favLeagueDetails[leagueDetail.leagueId] = leagueDetail;

        const fetchFavMatch = fetchFavMatches.find(item => item.id === match.id);

        const matches = favMatchesByLeagues[leagueDetail.leagueId] || [];
        if (match.status !== 'Played') {
            favMatchesByLeagues[leagueDetail.leagueId] = [
                ...matches,
                {...match, ...fetchFavMatch},
            ];
        }
    });

    const favSections = Object.entries(favMatchesByLeagues).map(entry => {
        const [leagueId, matches] = entry;
        return {
            title: favLeagueDetails[leagueId],
            data: matches,
        };
    });

    /*LIVE MATCHES CODES*/
    useEffect(() => {
        const today = new Date().toISOString().substr(0, 10);
        if (currentDate === today) {
            fetchLiteMatches().then(data => {
                console.log("1");
                const instantMatches = data.data.data;
                setLiveInstantMatches(instantMatches);
            });

            const interval = setInterval(() => {
                fetchLiteMatches().then(data => {
                    console.log("2");
                    const instantMatches = data.data.data;
                    setLiveInstantMatches(instantMatches);
                });
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [currentDate]);

    const liveMatches = [];
    const liveInstantLeaguesIndex = {};
    if (liveData) {
        liveData.forEach((leagues, index) => {
            const {leagueId, league, areaId, area} = leagues;
            liveInstantLeaguesIndex[leagueId] = index;
            const matchesWithAllSelectedVal = leagues.matches.filter(match => {
                return liveInstantMatches.some(instantMatch => {
                    if (match[statsType]) {
                        return match.id === instantMatch.id && instantMatch.status === 'Playing' &&
                            match[statsType]?.every(item => item.val.slice(0, -1) >= selectedPercent);
                    } else {
                        if (selectedPercent === 0) {
                            return match.id === instantMatch.id && instantMatch.status === 'Playing'
                        }
                    }
                });
            });
            matchesWithAllSelectedVal.forEach(match => {
                liveMatches.push({
                    leagueDetail: {leagueId, league, areaId, area},
                    match,
                });
            })
        });
    }

    let filteredLiveMatches = [];
    if (liveInstantMatches) {
        const liveInstant = liveInstantMatches.filter(
            item => item.status === 'Playing',
        );
        filteredLiveMatches = liveMatches.map(matches => {
            const findLiveInstant = liveInstant.find(
                match => match.id === matches.match.id,
            );
            return {
                ...matches,
                findLiveInstant,
            };
        });
    }

    const liveMatchesByLeagues = {};
    const liveLeagueDetails = {};
    filteredLiveMatches.forEach(liveMatch => {
        const {leagueDetail, match, findLiveInstant} = liveMatch;
        const matchAndLiveInstant = {...match, ...findLiveInstant};

        liveLeagueDetails[leagueDetail.leagueId] = leagueDetail;

        const matches = liveMatchesByLeagues[leagueDetail.leagueId] || [];
        liveMatchesByLeagues[leagueDetail.leagueId] = [...matches, matchAndLiveInstant];
    });

    const liveSections = Object.entries(liveMatchesByLeagues).map(entry => {
        const [leagueId, matches] = entry;
        return {
            title: liveLeagueDetails[leagueId],
            data: matches,
        };
    }).sort(
        (a, b) => liveInstantLeaguesIndex[a.title.leagueId] - liveInstantLeaguesIndex[b.title.leagueId],
    );

    const liveMatchButton = (live) => {
        setLive(!live)
    }

    /*PAGE CODES*/
    const activeStatButton = (item) => {
        if (item.toggle === toggles) {
            return {
                background: '#0c0c0c',
                color: 'white',
            }
        } else {
            return {}
        }
    }

    //Week Days Format
    const format = (day) => {
        return dayjs(day).format('ddd')
    }
    const formatDDDForMobile = (day) => {
        return dayjs(day).format('ddd')
    }

    const format2 = (day) => {
        return dayjs(day).format('DD')
    }

    const clickDays = async (day) => {
        await setCurrentDate(day)
    }

    const activeDayStyle = (day) => {
        if (currentDate === day.day) {
            return 'active-week-day'
        }
    }

    const generateStatsType = (stat, header, toggle) => {
        setStatsType(stat)
        setCurrentStats(header)
        setToggle(toggle)
    }

    const handleChange = (event) => {
        if (!isPro) {
            navigate('/join-premium');
        } else {
            setSelectedPercent(event.target.value);
        }
    };

    const disableSelect = () => {
        if (statsType === 'avgStats' || statsType === 'refereeStats') {
            return true;
        } else {
            return false;
        }
    }

    const daysFormatFunc = (date) => {
        const currentDate = new Date().toISOString().substr(0, 10);
        if (date.day === currentDate) {
            return (
                <div>
                    {
                        lang === 'tr' ?
                            <div className='home-page-week-days-container'>
                                <span>{format2(date.day)}</span>
                                <span className='stats-date-day-name'>Bugün</span>

                            </div>
                            :
                            <div className='home-page-week-days-container'>
                                <span>{format2(date.day)}</span>
                                <span className='stats-date-day-name'>Today</span>
                            </div>

                    }
                </div>
            )
        } else {
            return (
                <div className='home-page-week-days-container'>
                    <span>{format2(date.day)}</span>
                    <span className='stats-date-day-name'>{format(date.day)}</span>
                </div>
            )
        }
    }

    if (!fixturesData) {
        return (
            <div className=" const-width loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        Loading...
                    </div>
                </div>
            </div>
        )
    }

    return (
        <DocumentMeta {...statsMeta[lang]}>
            <div className="const-width fixture-section">
                <div className="fixtures-general-container">
                    {/*FOR WEB*/}
                    <div className="fixtures-top-header">
                        <div className="live-status-container">
                            <div className="live-container" onClick={() => liveMatchButton(live)}>
                                {
                                    live ?
                                        <div className="live-match">
                                            <img className="live-icon" src={livePng} alt=""/>
                                            {
                                                lang === 'tr' ?
                                                    <span className="live-text">Canlı</span>
                                                    :
                                                    <span className="live-text">Live</span>
                                            }
                                            <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                        </div>
                                        :
                                        <div className="not-live">
                                            <img className="not-live-icon" src={livePng} alt=""/>
                                            {
                                                lang === 'tr' ?
                                                    <span className="not-live-text">Canlı</span>
                                                    :
                                                    <span className="not-live-text">Live</span>
                                            }
                                            <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="stats-week-days-section">
                            {
                                week.map((day) => {
                                    return (
                                        <div key={day.day} className="week-days-container">
                                            <div onClick={() => clickDays(day.day)}
                                                 className={`${'dates'} ${activeDayStyle(day)}`}>
                                                {/*<span>{format2(day.day)}</span>
                                            <span className='stats-date-day-name'>{format(day.day)}</span>*/}
                                                {daysFormatFunc(day)}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="stats-button-container">
                            {
                                lang === 'tr' ?
                                    statsButtonsTR.map((item) => {
                                        return (
                                            <div
                                                style={activeStatButton(item)}
                                                className="stat-button"
                                                onClick={() => generateStatsType(item.type, item.header, item.toggle)}
                                                key={item.toggle}
                                            >
                                                {item.text}
                                            </div>
                                        )
                                    })
                                    :
                                    statsButtonsEN.map((item) => {
                                        return (
                                            <div
                                                style={activeStatButton(item)}
                                                className="stat-button"
                                                onClick={() => generateStatsType(item.type, item.header, item.toggle)}
                                                key={item.toggle}
                                            >
                                                {item.text}
                                            </div>
                                        )
                                    })
                            }
                        </div>

                        <div className='top-select-stats-list-container'>
                            <FormControl fullWidth>
                                {
                                    lang === 'en' ?
                                        <InputLabel id="demo-simple-select-label" className='stats-selection-input-label'>Filter</InputLabel>
                                        :
                                        <InputLabel id="demo-simple-select-label" className='stats-selection-input-label'>Filtrele</InputLabel>
                                }
                                <Select
                                    id="stat"
                                    value={selectedPercent}
                                    onChange={handleChange}
                                    defaultValue={selectedPercent}
                                    disabled={disableSelect()}
                                    style={{ marginTop: '-2px' }}
                                >
                                    {percentList[0][lang].map((item, index) => (
                                        <MenuItem key={index} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                    </div>
                    {/*FOR MOBILE*/}
                    <div className="fixtures-top-header-mobile const-width-content">
                        <div className={'live-and-weekdays-container-mobile'}>
                            <div className="live-status-container">
                                <div className="live-container" onClick={() => liveMatchButton(live)}>
                                    {
                                        live ?
                                            <div className="live-match">
                                                <img className="live-icon" src={livePng} alt=""/>
                                                {
                                                    lang === 'tr' ?
                                                        <span className="live-text">Canlı</span>
                                                        :
                                                        <span className="live-text">Live</span>
                                                }
                                                <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                            </div>
                                            :
                                            <div className="not-live">
                                                <img className="not-live-icon" src={livePng} alt=""/>
                                                {
                                                    lang === 'tr' ?
                                                        <span className="not-live-text">Canlı</span>
                                                        :
                                                        <span className="not-live-text">Live</span>
                                                }
                                                <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="week-days-section">
                                {
                                    week.map((day) => {
                                        return (
                                            <div key={day.day} className="week-days-container">
                                                <div onClick={() => clickDays(day.day)}
                                                     className={`${'dates'} ${activeDayStyle(day)}`}>
                                                    <span>{format2(day.day)}</span>
                                                    <span>{formatDDDForMobile(day.day)}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="stats-button-container">
                            {
                                lang === 'tr' ?
                                    statsButtonsTR.map((item) => {
                                        return (
                                            <div
                                                style={activeStatButton(item)}
                                                className="stat-button"
                                                onClick={() => generateStatsType(item.type, item.header, item.toggle)}
                                                key={item.toggle}
                                            >
                                                {item.text}
                                            </div>
                                        )
                                    })
                                    :
                                    statsButtonsEN.map((item) => {
                                        return (
                                            <div
                                                style={activeStatButton(item)}
                                                className="stat-button"
                                                onClick={() => generateStatsType(item.type, item.header, item.toggle)}
                                                key={item.toggle}
                                            >
                                                {item.text}
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                    {/*FAV SECTIONS*/}
                    {
                        favSections && favMatches.length > 0 && favSections.map((league) => {
                            const favMatches = favMatchesByLeagues[league.title.leagueId] || [];

                            const matchesWithAllSelectedVal = favMatches.filter(match => {
                                /*return statsList.every((stat) => {
                                    return match[stat.name]?.every((item) => item.val.slice(0, -1) >= selectedPercent);
                                });*/
                                if (match[statsType]) {
                                    return match[statsType]?.every((item) => item.val.slice(0, -1) >= selectedPercent);
                                } else {
                                    if (selectedPercent === 0) {
                                        return match
                                    }
                                }
                            });

                            return (
                                favMatches.length === 0 ? null :
                                    <LeagueAndMatchesTable key={league.title.leagueId} favMatches={matchesWithAllSelectedVal}
                                                           statsType={statsType}
                                                           currentStats={currentStats} league={league} matches={matchesWithAllSelectedVal}/>
                            )
                        })
                    }
                    {/*STATS SECTIONS*/}
                    {
                        sections && !live && sections.map((league) => {
                            const matches = matchesByLeagues[league.title.leagueId] || [];
                            return (
                                matches.length === 0 ? null :
                                    <LeagueAndMatchesTable key={league.title.leagueId} statsType={statsType}
                                                           currentStats={currentStats} league={league}
                                                           matches={matches}/>
                            )
                        })
                    }
                    {/*LIVE SECTIONS*/}
                    {
                        liveSections.length !== 0 && live && liveSections.map((league) => {
                            const liveMatches = liveMatchesByLeagues[league.title.leagueId] || [];
                            return (
                                <div>
                                    {
                                        liveMatches.length === 0 ? null :
                                            <LeagueAndMatchesTable key={league.title.leagueId} statsType={statsType}
                                                                   currentStats={currentStats}
                                                                   league={league} matches={liveMatches}/>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        liveSections?.length === 0 && live &&
                        <div className={'league-name-container no-live-matches-container'}>
                            {
                                lang === 'tr' ?
                                    <span className={'no-live-matches-text'}>İstediğiniz filtreye uygun veya canlı maç yok!</span>
                                    :
                                    <span className={'no-live-matches-text'}>There are no live matches or match the filter you want!</span>
                            }
                        </div>
                    }
                    {
                        sections?.length === 0 &&
                        <div className={'league-name-container no-live-matches-container'}>
                            {
                                lang === 'tr' ?
                                    <span className={'no-live-matches-text'}>İstediğiniz filtreye uygun eşleşme yok!</span>
                                    :
                                    <span className={'no-live-matches-text'}>There are no matches suitable for the filter you want!</span>
                            }
                        </div>
                    }
                </div>
            </div>
        </DocumentMeta>
    );
}

export default Stats;
