import axios from 'axios';

const api2 = axios.create({
    baseURL: 'https://santra24.6stats.com/api/',
    headers: {
        'x-platform': 'XFStats',
    },
});

//LOGIN
export const login = () => api2.post(`user/login`);

//LIVE SCORES
export const fetchLiveScoresHome = date => api2.get(`v1/football/matches/byLeague?date=${date}`);

//Stats
export const fetchFixtures = date => api2.get(`v1/betassist/fixtures?date=${date}`);

export const fetchLiteMatches = () => api2.get('v1/football/matches/lite');

//Favorite Matches
export const fetchFavoritesMatches = ids =>
    api2.get(`v1/football/matches/list?ids=${ids}`);
export const fetchFavoritesBetModeMatches = ids =>
    api2.get(`v1/bvscores/matches/list?ids=${ids}`);

//Match Detail
export const fetchMatchDetail = (matchId) =>
    api2.get(`v1/football/match/${matchId}`);
export const fetchTeamDetail = (matchId, event) =>
    api2.get(`v1/betassist/match/${matchId}/stats?event=${event}`);
export const fetchTimeLine = (matchId) =>
    api2.get(`v1/football/match/${matchId}/events`);
export const fetchMatchDetailStatistic = (matchId) =>
    api2.get(`v1/football/match/${matchId}/stats`);
export const fetchH2h = (matchId) =>
    api2.get(`v1/football/match/${matchId}/h2h/v2`);
export const fetchH2hAll = (matchId) =>
    api2.get(`v1/football/match/${matchId}/h2h/all`);
export const fetchH2hAllStats = (matchId) =>
    api2.get(`v1/football/match/${matchId}/h2h/all/stats`);
export const fetchMatchDetailForLmt = (id) => api2.get(`v1/sport/football/match/${id}`);

//TEAM DETAILS
export const fetchTeamTeamDetail = (teamId) =>
    api2.get(`v1/football/teams/${teamId}`);
export const fetchTeamStandings = (teamId) =>
    api2.get(`v1/football/teams/${teamId}/standings`);
export const fetchTeamResults = (teamId) =>
    api2.get(`v1/football/teams/${teamId}/results`);
export const fetchTeamFixtures = (teamId) =>
    api2.get(`v1/football/teams/${teamId}/fixtures`);

//LEAGUE DETAIL
export const fetchLeagueDetail = (leagueId) => api2.get(`v1/football/leagues/${leagueId}`);
export const fetchLeagueStandings = (seasonId) => api2.get(`v1/football/leagues/season/${seasonId}/standings`);
export const fetchLeagueStats = (seasonId, type) => api2.get(`v1/betassist/league-stats/season/${seasonId}?type=${type}`);
export const fetchDefaultLeague = () => api2.get(`v1/football/leagues/default`);
export const fetchPopularLeagues = () => api2.get(`v1/football/leagues/popular`);
export const fetchAllLeagues = () => api2.get(`v1/football/leagues/all`);
export const fetchAreas = () => api2.get(`v1/football/areas`);
export const fetchResults = (seasonId) => api2.get(`v1/football/leagues/season/${seasonId}/results`);
export const fetchLeagueFixtures = (seasonId) => api2.get(`v1/football/leagues/season/${seasonId}/fixtures`);

//Bet Radar Tips>
export const fetchBetRadarTips = ids =>
    api2.get('v1/betassist/betradar/matches/v2?live=0');

//BEST TEAMS
export const fetchBestTeams = (type) => api2.get(`v1/betassist/stats/best-teams?type=${type}`);

//TREND MATCHES
export const fetchTrendMatches = (type) => api2.get(`v1/betassist/stats/best-matches?type=${type}`);

//BET MODE
export const fetchLiveScores = (date) => api2.get(`v1/bvscores/matches/byLeague?date=${date}`);

//BET MODE INSTANT
export const fetchLiveScoresInstant = (date) => api2.get(`v1/bvscores/matches/byTime?date=${date}`);

//TV GUIDE
export const fetchTvMatches = () => api2.get(`v1/tv/matches`);




