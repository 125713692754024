import React, {useContext} from 'react';
import padLock from '../../assets/icons/padlock.png'
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../context/AppContext";

function StatsRow(props) {
    const {isPro} = useContext(AppContext);

    const {statsType} = props;

    const navigate = useNavigate();

    const statStyleWidth = () => {
        if (statsType === 'avgStats') {
            return 'stat-style-width-red'
        } else {
            return 'stat-style-width-other'
        }
    }

    const toJoinPremium = () => {
        navigate('/join-premium')
    }

    const statsColor = (stats) => {
        if (statsType !== 'avgStats' && statsType !== 'refereeStats') {
            if (stats > 90) {
                return {
                    background: '#72da2373',
                    color: '#095035',
                    padding: '4px 7px'
                }
            } else if (stats > 80) {
                return {
                    background: '#72da233d',
                    color: '#095035',
                    padding: '4px 7px'
                }
            } else if (stats > 50) {
                return {
                    background: '#f6f7cd',
                    color: '#656500',
                    padding: '4px 7px'
                }
            } else {
                return {
                    background: '#ffe9e6',
                    color: '#ff7563',
                    padding: '4px 7px'
                }
            }
        } else {
            return {
                border: '1px solid #d3d3d3',
                borderRadius: '5px',
                padding: '4px'
            }
        }
    }

    return (
        <td className={statStyleWidth()}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*<span className={'stats-type-mobile'}>{props.stat.name}</span>*/}
                {/*<span className={'fixture-stat-style'}>{props.stat.val}</span>*/}
                <td className={props.borderStyle}>
                    {
                        isPro ?
                            <div>
                                <span className={'stats-type-mobile'}>{props.stat.name}</span>
                                <span style={statsColor(props.stat.val.slice(0, -1), props.stat.name)}>{props.stat.val}</span>
                            </div>
                            :
                            <div onClick={() => toJoinPremium()}>
                                <span className={'stats-type-mobile'}>{props.stat.name}</span>
                                <img className='pad-lock-icon' src={padLock} alt=""/>
                            </div>
                    }

                </td>
                {/*<span>{this.statStyle(stat.avg)}</span>*/}
            </div>
        </td>
    );
}

export default StatsRow;
