import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./header/Header";
import LiveScores from "./live-scores/LiveScores";
import Stats from "./stats/Stats";
import TrendMatches from "./trend-matches/TrendMatches";
import MatchDetails from "./match-details/MatchDetails";
import TeamDetails from "./team-details/TeamDetails";
import LeagueDetails from "./league-details/LeagueDetails";
import BestTeams from "./best-teams/BestTeams";
import BetMode from "./bet-mode/BetMode";
import TvGuide from "./tv/TvGuide";
import Terms from "./base-pages/Terms";
import Privacy from "./base-pages/Privacy";
import Contact from "./base-pages/Contact";
import SignIn from "./Auth/SignIn";
import SignUp from "./Auth/SignUp";
import JoinPremium from "./premium/JoinPremium";
import PaymentSuccess from "./payment/PaymentSuccess";
import PaymentCancel from "./payment/PaymentCancel";
import UserAccount from "./Auth/UserAccount";
import Footer from "./footer/Footer";
import CancelSubscription from "./premium/CancelSubscription";

function Application(props) {
    return (
        <div style={{background: '#3866b0'}}>
            <BrowserRouter>
                <Header/>
                <div className={'const-width'}>
                    <Routes>
                        <Route exact path="/" element={<LiveScores/>}></Route>
                        <Route exact path="/stats" element={<Stats/>}></Route>
                        <Route path="/trend-matches/:stats?/:type?" element={<TrendMatches/>}></Route>
                        <Route path="/match-detail/:id" element={<MatchDetails/>}></Route>
                        <Route path="/team-detail/:id" element={<TeamDetails/>}></Route>
                        <Route path="/league/:league?/:leagueId?/:seasonId?" element={<LeagueDetails/>}></Route>
                        {/*<Route path="/bet-radar" element={<BetRadar/>}></Route>*/}
                        <Route path="/best-teams/:stats?/:type?" element={<BestTeams/>}></Route>
                        <Route path="/scoreradar" element={<BetMode/>}></Route>
                        <Route path="/tv" element={<TvGuide/>}></Route>
                        <Route path="/terms" element={<Terms/>}></Route>
                        <Route path="/privacy" element={<Privacy/>}></Route>
                        <Route path="/contact" element={<Contact/>}></Route>
                        <Route path="/sign-in/:redirect?" element={<SignIn/>}></Route>
                        <Route path="/sign-up" element={<SignUp/>}></Route>
                        <Route path="/join-premium" element={<JoinPremium/>}></Route>
                        <Route path="/payment/success/:session_id?" element={<PaymentSuccess/>}></Route>
                        <Route path="/payment/cancel" element={<PaymentCancel/>}></Route>
                        <Route path="/account-detail/:username" element={<UserAccount/>}></Route>
                        <Route path="/account-detail/:username/cancel-subscription" element={<CancelSubscription/>}></Route>
                    </Routes>
                </div>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default Application;
