import React, {useContext} from 'react';
import './Footer.css'
import Insta from '../../assets/icons/instagram-dark.svg'
import Facebook from '../../assets/icons/facebook-dark.svg'
import Twitter from '../../assets/icons/twitter-dark.svg'
import TR from '../../assets/flags/TR.png'
import EN from '../../assets/flags/EN.png'
import ES from '../../assets/flags/ES.png'
import DE from '../../assets/flags/DE.png'
import FR from '../../assets/flags/FR.png'
import logo from '../../assets/icons/logo.png'
import {Link} from "react-router-dom";
import {AppContext} from "../../context/AppContext";

function Footer(props) {
    const {lang} = useContext(AppContext);

    return (<div className={'footer-general-section'}>
        <div className='const-width footer-top-container'>
            <div className={'footer-container'}>
                <span className='footer-ul-title'>
                XFStats
                </span>
                <ul className='footer-ul-container'>
                    <li className='footer-li'>
                        <Link target="_blank" to={'/terms'}>Kullanım Şartları</Link>
                    </li>
                    <li className='footer-li'>
                        <Link target="_blank" to={'/contact'}>İletişim</Link>
                    </li>
                </ul>
            </div>

            <div className={'footer-container'}>
                <span className='footer-ul-title'>
                Takip Et
                </span>
                <ul className='footer-ul-container'>
                    <li className='footer-li'>
                        <img className='social-media-icons' src={Insta} alt=""/>
                        Instagram
                    </li>
                    <li className='footer-li'>
                        <img className='social-media-icons' src={Facebook} alt=""/>
                        Facebook
                    </li>
                    <li className='footer-li'>
                        <img className='social-media-icons' src={Twitter} alt=""/>
                        Twitter
                    </li>
                </ul>
            </div>

            <div className={'footer-container'}>
                <span className='footer-ul-title'>
                Mobil Uygulamalar
                </span>
                <ul className='footer-ul-container'>
                    <li className='footer-li'>
                        <a href="https://apps.apple.com/app/id6445814942" target="_blank" rel="noopener">
                            <div className="footer_app_store"></div>
                        </a>
                    </li>
                    <li className='footer-li'>
                        <a href="https://play.google.com/store/apps/details?id=app.han3.xfstats&pli=1" target="_blank" rel="noopener">
                            <div className="footer_play_store"></div>
                        </a>
                    </li>
                </ul>
            </div>

            <div className={'footer-container'}>
                <span className='footer-ul-title'>
                Diller
                </span>
                <ul className='footer-ul-container'>
                    <li className='footer-li'>
                        <img className='flags' src={TR} alt=""/>
                        Türkçe
                    </li>
                    <li className='footer-li'>
                        <img className='flags' src={EN} alt=""/>
                        İngilizce
                    </li>
                    <li className='footer-li'>
                        <img className='flags' src={DE} alt=""/>
                        Almanca
                    </li>
                    <li className='footer-li'>
                        <img className='flags' src={FR} alt=""/>
                        Fransızca
                    </li>
                    <li className='footer-li'>
                        <img className='flags' src={ES} alt=""/>
                        İspanyolca
                    </li>
                </ul>
            </div>
        </div>

        <div className='const-width footer-bottom-container'>
            <div style={{display: 'flex', justifyContent: 'center', margin: '10px 0'}}>
                <img className='logo2-style' src={logo} alt=""/>
            </div>

            <span className='copyright-text'>Copyright © 2023 XFStats</span>
            {
                lang === 'en' ?
                    <h1 className='footer-title-h1'>LiveScore - Latest Football Scores, Results, Fixtures and
                        Tables</h1>
                    :
                    <h1 className='footer-title-h1'>Canlı skorlar, maç sonuçları, canlı iddaa sonuçları, fikstür ve
                        Süper Lig puan durumu</h1>
            }
            <span className='gamble-text'>BEGAMBLEAWARE.ORG Gamble Responsibly. Gambling Therapy.</span>
            <span className='followers-text'>Followers must be 18+</span>
            <span className='footer-text'>XFStats.com'da yer alan tüm sayısal veriler, 1000'den fazla futbol liginden canlı skorlar, puan durumu, gol atan oyuncular, futbol ilk yarı sonuçları, kırmızı kartlar, sarı kartlar, korner ve şut bilgileri, iddaa oranları, iddaa programı, maç sonuçları, istatistik ve tahmin bilgileri sadece bilgilendirme amaçlıdır. Bilgilerin doğruluğunu sağlamak için gereken gayret gösterilmektedir ancak bilgilerin doğruluğunun kontrolü kullanıcıların kendi sorumluluğundadır. Oluşabilecek hatalardan, maddi/manevi zararlardan XFStats.com sorumlu tutulamaz.</span>
        </div>

    </div>);
}

export default Footer;
