import React, {useState} from 'react';
import StatsHeader from "../stats/StatsHeader";
import LiveScoresMatches from "./LiveScoresMatches";
import LiveScoresTableHeader from "./LiveScoresTableHeader";
import noFlagIcon from "../../assets/icons/noFlagIcon.png";
import {colorTheme} from "../ColorCss";

function LiveScoresLeagueAndMatchesTable(props) {

    const {league, matches,favMatches} = props;

    const [statsHeader, setStatsHeader] = useState({
        liveScoresTableHeader: [
            {name: 'İY', text: 'İY', width: 'small'},
            {name: 'Iddaa', text: '', width: 'small'},
            {name: 'K', text: '', width: 'small'},
            {name: 'MBS', text: '', width: 'small'},
            {name: 'Fav', text: '', width: 'small'},
            {name: '1', text: '1'},
            {name: 'X', text: 'X'},
            {name: '2', text: '2'},
            {name: '2.5O', text: '2.5O'},
            {name: '2.5U', text: '2.5U'},
        ]
    });

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <div className="live-scores-cards-container">
            <table className="live-scores-table-section stats-table-header">
                <tbody>
                <tr className="live-scores-league-name-container">
                    {
                        <td className="league-name-and-flag-container">
                            {
                                favMatches && favMatches.length > 0 ?
                                    <span style={{color: colorTheme.normalTeam}} className="favorites-title">Favorites</span> :
                                    <div
                                        onClick={() => {
                                            window.open(`/league/${league.title.area.toLowerCase()}-${league.title.league.toLowerCase().replace(/\s+/g, '-')}/${league.title.leagueId}/${league.title.seasonId}`)
                                        }}
                                        className={'league-name-and-flag'}
                                    >
                                        {
                                            league?.title?.areaId &&
                                            <img className="live-scores-country-flag"
                                                 src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.title.areaId}.png`}
                                                 onError={(e) => onImageError(e)}
                                                 alt="country"/>
                                        }
                                        <a style={{color: colorTheme.normalTeam}} className="live-scores-area-info-container">
                                            <strong>{league.title.area} </strong> - {league.title.league}</a>
                                    </div>
                            }

                        </td>
                    }
                    {
                        statsHeader['liveScoresTableHeader'].map((stHeader) => {
                            return (
                                <LiveScoresTableHeader key={stHeader.name} stHeader={stHeader}/>
                            )
                        })
                    }
                </tr>
                </tbody>
            </table>
            <LiveScoresMatches league={league} matches={matches}></LiveScoresMatches>
        </div>
    );
}

export default LiveScoresLeagueAndMatchesTable;
