import React from 'react';

function LiveScoresMatchStatsRow(props) {

    const {match} = props;

    const statStyleWidth = () => {
        return 'live-scores-stat-style-width-other'
    }

    return (
        <td style={{width: '24%'}}>
            <td className={statStyleWidth()}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {
                        match.status === 'Fixture' && <span>-</span>
                    }
                    {
                        match.status === 'Playing' && <span>{match.hp1} - {match.ap1}</span>
                    }
                    {
                        match.status === 'Played' && <span>{match.hp2} - {match.ap2}</span>
                    }
                    {/*<span className={'stats-type-mobile'}>{match.hp1} - {match.ap1}</span>*/}
                    {/*<span>{this.statStyle(stat.avg)}</span>*/}
                </div>
            </td>

        </td>
    );
}

export default LiveScoresMatchStatsRow;
