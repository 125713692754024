import React, {useContext, useState} from 'react';
import {AppContext} from "../../context/AppContext";
import dayjs from "dayjs";
import favIcon from "../../assets/icons/star-fill.png";
import favOff from "../../assets/icons/star.png";
import StatsRow from "../stats/StatsRow";
import StatsRowNoInfo from "../stats/StatsRowNoInfo";
import LiveScoresMatchStatsRow from "./LiveScoresMatchStatsRow";
import {colorTheme} from "../ColorCss";
import redCard from '../../assets/icons/mackolik-rcard.gif';
import IddaaIcon from '../../assets/icons/iddaa-icon.png';
import compareIcon from '../../assets/icons/compare.gif';
import mbs3 from '../../assets/icons/mbs3.png';
import liveIcon from '../../assets/icons/live-icon.png';
import iddaaLive from "../../assets/icons/iddaa-live.png";
import iddaaNLive from "../../assets/icons/iddaa-nlive.png";

function LiveScoresMatches(props) {

    const {addAndRemoveFavLiveScoresMatches, isFavLiveScores, lang} = useContext(AppContext);
    const {matches, league} = props;

    const startTime = (value) => {
        return dayjs(value).format("HH.mm")
    }

    const statStyleWidth = () => {
        return 'live-scores-stat-style-width-other'
    }

    const statStyleWidthSmall = () => {
        return 'live-scores-stat-style-width-small'
    }

    return (
        <div>
            {
                matches.map((match, index) => {
                    return (
                        <div key={match.id}>
                            {/*WEB*/}
                            <div className="live-scores-matches-container">
                                <table className="live-scores-table-section">
                                    <tbody>
                                    <tr className={index % 2 === 0 ? 'live-scores-table-row' : 'live-scores-table-row-stripped'}>
                                        <td className="live-scores-match-info-td">
                                            <div className="live-scores-match-info-container">

                                                {/*START TIME*/}
                                                <span style={{color: colorTheme.dark444}}
                                                      className='live-scores-match-row-info'>
                                                            {startTime(match.dateTimeUtc)}
                                                </span>

                                                {/*MATCH SITUATION*/}
                                                <div>
                                                    {match.status === 'Played' &&
                                                        <span style={{color: colorTheme.dark333}}
                                                              className='live-scores-match-row-info live-scores-ms'>
                                                            MS
                                                        </span>}
                                                    {match.st === 'HT' &&
                                                        <span style={{color: colorTheme.matchTime}}
                                                              className="live-scores-live-match-min">
                                                            HT
                                                        </span>}
                                                    {match.status === 'Playing' && match.st !== 'HT' &&
                                                        <span style={{color: colorTheme.matchTime}}
                                                              className="live-scores-live-match-min">
                                                            {match.min}'
                                                        </span>}
                                                    {match.status === 'Postponed' &&
                                                        <div>
                                                            {
                                                                lang === 'en' ?
                                                                    <span className="live-scores-live-match-min">Post.</span>
                                                                    :
                                                                    <span className="live-scores-live-match-min">Ert.</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                                {/*IDDAA STATUS*/}
                                                {
                                                    index % 3 === 0 ?
                                                        <img style={{width: '12px', height: '12px'}} src={iddaaLive}
                                                             alt="İddaa"/>
                                                        :
                                                        <img style={{width: '12px', height: '12px'}} src={iddaaNLive}
                                                             alt="İddaa"/>
                                                }

                                                {/*RED CARD*/}
                                                <div className="card-container">
                                                    {match.hred > 0 &&
                                                        <img src={redCard} alt="red"/>
                                                    }
                                                </div>

                                                {/*HOME TEAM NAME*/}
                                                <a
                                                    onClick={() => {
                                                        window.open(`/team-detail/${match.homeId}`)
                                                    }}
                                                    style={{color: colorTheme.normalTeam}}
                                                    className="live-scores-match-team-names">
                                                    {match.home}
                                                </a>

                                                {/*MATCH SCORE*/}
                                                <div
                                                    className="score-container live-scores-texts-12px"
                                                    onClick={() => {
                                                        localStorage.setItem(`teams-state:${match.id}`, JSON.stringify(
                                                            {
                                                                home: match.home,
                                                                homeId: match.homeId,
                                                                away: match.away,
                                                                awayId: match.awayId,
                                                                time: match.dateTimeUtc,
                                                                seasonId: match.seasonId
                                                            }
                                                        ))
                                                        window.open(`/match-detail/${match.id}`)
                                                    }}
                                                >
                                                    {match.status === 'Playing' &&
                                                        <a style={{color: colorTheme.liveMatchResult}}
                                                           className="live-scores-live-match-score">
                                                            {match.hscore} - {match.ascore}
                                                        </a>}
                                                    {match.status === 'Played' &&
                                                        <a
                                                            style={{
                                                                color: colorTheme.normalTeam
                                                            }}
                                                            className='live-scores-ended-matches-score'
                                                        >
                                                            {match.hscore} - {match.ascore}
                                                        </a>}
                                                    {match.status === 'Fixture' &&
                                                        <span style={{
                                                            color: colorTheme.normalTeam,
                                                            fontSize: '10px'
                                                        }}>-</span>}
                                                </div>

                                                {/*AWAY TEAM NAME*/}
                                                <a
                                                    onClick={() => {
                                                        window.open(`/team-detail/${match.awayId}`)
                                                    }}
                                                    style={{color: colorTheme.normalTeam}}
                                                    className="live-scores-match-team-names live-scores-away-team-name">{match.away}</a>

                                                {/*RED CARD*/}
                                                <div className="card-container">
                                                    {match.ared > 0 &&
                                                        <img src={redCard} alt="red"/>
                                                    }
                                                </div>

                                                {/*FAV*/}
                                                <div className='live-scores-fav-container-for-mobile'>
                                                    {match.status !== 'Played' &&
                                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                                            {
                                                                isFavLiveScores(match.id) ?
                                                                    <img
                                                                        onClick={() => addAndRemoveFavLiveScoresMatches(league, match)}
                                                                        className="fav-icon" src={favIcon} alt="fav"/> :
                                                                    <img
                                                                        onClick={() => addAndRemoveFavLiveScoresMatches(league, match)}
                                                                        className="fav-icon" src={favOff} alt="fav"/>
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </td>
                                        {/*MATCH STATS ROW*/}
                                        {/*IY*/}
                                        <td className={statStyleWidthSmall()}>
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                {
                                                    match.status !== 'Fixture' ?
                                                        <span
                                                            style={{color: colorTheme.dark444}}
                                                            className='live-scores-1-half'>{match.hp1} - {match.ap1}</span> :
                                                        <span style={{color: colorTheme.dark444}}
                                                              className='live-scores-1-half'>-</span>
                                                }
                                                {/*<span className={'stats-type-mobile'}>{match.hp1} - {match.ap1}</span>*/}
                                            </div>
                                        </td>
                                        {/*IDDAA*/}
                                        <td className={statStyleWidthSmall()}>
                                            <div className='live-scores-rate-container'>
                                                <img style={{width: '15px', height: '12px'}} src={IddaaIcon}
                                                     alt="İddaa"/>
                                            </div>
                                        </td>
                                        {/*COMPARE*/}
                                        <td className={statStyleWidthSmall()}>
                                            <div className='live-scores-rate-container'>
                                                <img style={{width: '10px', height: '11px'}} src={compareIcon}
                                                     alt="Compare"/>
                                            </div>
                                        </td>

                                        {/*MB*/}
                                        <td className={statStyleWidthSmall()}>
                                            <div className='live-scores-rate-container'>
                                                <img style={{width: '10px', height: '11px'}} src={mbs3}
                                                     alt="Compare"/>
                                            </div>
                                        </td>

                                        {/*FAV*/}
                                        <td className={statStyleWidthSmall()}>
                                            {match.status !== 'Played' &&
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    {
                                                        isFavLiveScores(match.id) ?
                                                            <img
                                                                onClick={() => addAndRemoveFavLiveScoresMatches(league, match)}
                                                                className="fav-icon" src={favIcon} alt="fav"/> :
                                                            <img
                                                                onClick={() => addAndRemoveFavLiveScoresMatches(league, match)}
                                                                className="fav-icon" src={favOff} alt="fav"/>
                                                    }
                                                </div>
                                            }
                                        </td>
                                        {/*1*/}
                                        <td style={{color: colorTheme.normalTeam}} className={statStyleWidth()}>
                                            <div className='live-scores-rate-container live-scores-rate-bg'>
                                                {
                                                    match.o1 ?
                                                        <span className='live-scores-odds'>{match.o1}</span>
                                                        :
                                                        <span className='live-scores-odds'>3.5</span>
                                                }
                                            </div>
                                        </td>
                                        {/*X*/}
                                        <td style={{color: colorTheme.normalTeam}} className={statStyleWidth()}>
                                            <div className='live-scores-rate-container live-scores-rate-bg'>
                                                {
                                                    match.ox ?
                                                        <span className='live-scores-odds'>{match.ox}</span>
                                                        :
                                                        <span className='live-scores-odds'>3.5</span>
                                                }
                                            </div>
                                        </td>
                                        {/*2*/}
                                        <td style={{color: colorTheme.normalTeam}} className={statStyleWidth()}>
                                            <div className='live-scores-rate-container live-scores-rate-bg'>
                                                {
                                                    match.o2 ?
                                                        <span className='live-scores-odds'>{match.o2}</span>
                                                        :
                                                        <span className='live-scores-odds'>3.5</span>
                                                }
                                            </div>
                                        </td>
                                        {/*2.5O*/}
                                        <td style={{color: colorTheme.normalTeam}} className={statStyleWidth()}>
                                            <div className='live-scores-rate-container live-scores-rate-bg'>
                                                {
                                                    match.oo ?
                                                        <span className='live-scores-odds'>{match.oo}</span>
                                                        :
                                                        <span className='live-scores-odds'>3.5</span>
                                                }
                                            </div>
                                        </td>
                                        {/*2.5U*/}
                                        <td style={{color: colorTheme.normalTeam}} className={statStyleWidth()}>
                                            <div className='live-scores-rate-container live-scores-rate-bg'>
                                                {
                                                    match.ou ?
                                                        <span className='live-scores-odds'>{match.ou}</span>
                                                        :
                                                        <span className='live-scores-odds'>3.5</span>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/*MOBILE*/}
                            {/*<div className="live-scores-section-mobile match-team-names">
                                <table className="live-scores-table-section">
                                    <tbody>
                                    <tr className="live-scores-table-row">
                                        <td className="match-info-td">
                                            <div className="live-scores-match-info-container">
                                                <div>
                                                    {match.status !== 'Played' &&
                                                        <div>
                                                            {
                                                                isFav(match.id) ?
                                                                    <img
                                                                        onClick={() => addAndRemoveFavMatches(league, match)}
                                                                        className="fav-icon" src={favIcon} alt="fav"/> :
                                                                    <img
                                                                        onClick={() => addAndRemoveFavMatches(league, match)}
                                                                        className="fav-icon" src={favOff} alt="fav"/>
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                                <div>
                                                    {match.status === 'Fixture' &&
                                                        <span>{startTime(match.dateTimeUtc)}</span>}
                                                    {match.status === 'Played' && <span>MS</span>}
                                                    {match.status === 'Playing' && !match.status !== 'Half Time' &&
                                                        <span className="live-match-min">{match.min}'</span>}
                                                    {match.halfTime && <span>İY</span>}
                                                </div>

                                                <div className="card-container">
                                                    {match.hred > 0 &&
                                                        <div className="match-card red-card">{match.hred}</div>}
                                                </div>

                                                <span className="match-team-name">{match.home}</span>
                                                <div className="score-container" to={`/match-detail/${match.id}`}
                                                     onClick={() => {
                                                         localStorage.setItem("teams-state", JSON.stringify(
                                                             {
                                                                 home: match.home,
                                                                 homeId: match.homeId,
                                                                 away: match.away,
                                                                 awayId: match.awayId,
                                                                 time: match.dateTimeUtc
                                                             }
                                                         ))
                                                         window.open(`/match-detail/${match.id}`)
                                                     }}>
                                                    {match.status === 'Playing' &&
                                                        <span><span
                                                            style={{color: '#c00'}}>{match.hscore} -</span>  <span
                                                            style={{color: '#c00'}}>{match.ascore}</span></span>}
                                                    {match.status === 'Played' &&
                                                        <span
                                                            style={{color: 'black'}}>{match.hscore} - {match.ascore}</span>}
                                                    {match.status === 'Fixture' &&
                                                        <span style={{color: 'black', fontSize: '.8rem'}}>-</span>}
                                                </div>
                                                <span className="match-team-name away-team-name">{match.away}</span>

                                                <div className="card-container">
                                                    {match.ared > 0 &&
                                                        <div className="match-card red-card">{match.ared}</div>}
                                                </div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style={{display: 'flex'}}>
                                        {
                                            match[statsType] ?
                                                match[statsType].map((stat) => {
                                                    return (
                                                        <StatsRow key={stat.name} borderStyle={statStyle(stat)}
                                                                  stat={stat}
                                                                  statsType={statsType}/>
                                                    )
                                                }) :
                                                <StatsRowNoInfo borderStyle={'no-info-stats'}/>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </div>*/}
                        </div>
                    )
                })
            }
        </div>
    );
}

    export default LiveScoresMatches;
