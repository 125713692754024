import React from 'react';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import noTeamLogo from "../../../assets/icons/noTeamLogo.png";
import {useParams} from "react-router-dom";

function TeamStandings(props) {

    const {teamStandingsData, lang} = props;

    const params = useParams();

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    const teamNameStyle = (teamId) => {
        if (teamId === teamStandingsData?.homeId) {
            return {
                color: '#1a90ff',
                fontWeight: '600'
            }
        } else if (teamId === teamStandingsData?.awayId) {
            return {
                color: '#ff9800',
                fontWeight: '600'
            }
        }
    }

    const bgStyle = (team) => {
        if (params.id === team.teamId ) {
            return '#fff3a5'
        }
    }

    const positionStyle = (pos) => {
        switch (pos) {
            case 1: {
                return {background: '#3795FA', color: 'white'}
            }
            case 2: {
                return {background: '#3795FA', color: 'white'}
            }
            case 3: {
                return {background: '#89d3f5', color: 'white'}
            }
        }


    }

    console.log('teamStandingsData', teamStandingsData);

    return (
        <div className={teamStandingsData ? 'standings-section-with-margin' : 'standings-section-without-margin'}>
            {
                teamStandingsData &&
                <div>
                    {/*<div className='match-detail-standings-league-container'>
                        <img className="match-detail-standings-league-flag"
                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${teamStandingsData?.areaId}.png`}
                             alt=""/>
                        <a className='match-detail-standings-league-name'>{teamStandingsData?.league}</a>
                    </div>*/}

                    <div>
                        {
                            teamStandingsData.map((items, index) => {
                                return (
                                    <div>
                                        {
                                            items.name && <div className='team-detail-standings-group-name'>{items.name}</div>
                                        }

                                        {
                                            lang === 'tr' ?
                                                <div className='standings-header-row-container standings-titles'>
                                                    <div></div>
                                                    <div></div>
                                                    <div>O</div>
                                                    <div>G</div>
                                                    <div>B</div>
                                                    <div>M</div>
                                                    <div>P</div>
                                                </div>
                                                :
                                                <div className='standings-header-row-container standings-titles'>
                                                    <div></div>
                                                    <div></div>
                                                    <div>MP</div>
                                                    <div>W</div>
                                                    <div>D</div>
                                                    <div>L</div>
                                                    <div>PTS</div>

                                                </div>
                                        }

                                        {
                                            items.rows.map((items, index) => {
                                                return (
                                                    <div>
                                                        <div style={{background: bgStyle(items)}}
                                                             className={index % 2 === 0 ? 'standings-header' : 'standings-header-stripe-row'}>
                                                            <div style={positionStyle(items.position)} className='team-detail-standings-position'>
                                                                {items.position}
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    window.open(`/team-detail/${items.teamId}`)
                                                                }}
                                                                style={teamNameStyle(items.teamId)}
                                                                className='standings-team-name-container'>
                                                                <a className='standings-team-name-text'>{items.teamName}</a>
                                                            </div>

                                                            <div>
                                                                {items.played}
                                                            </div>
                                                            <div>
                                                                {items.win}
                                                            </div>
                                                            <div>
                                                                {items.draw}
                                                            </div>
                                                            <div>
                                                                {items.loss}
                                                            </div>
                                                            <div className='match-detail-standings-points'>
                                                                {items.points}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default TeamStandings;
