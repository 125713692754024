import React, {useContext, useState} from 'react';
import StatsHeader from "./StatsHeader";
import Matches from "./Matches";
import {AppContext} from "../../context/AppContext";
import noFlagIcon from "../../assets/icons/noFlagIcon.png";

function LeagueAndMatchesTable(props) {
    const {lang} = useContext(AppContext);

    const {league, currentStats, matches, statsType, favMatches} = props;

    const [statsHeader, setStatsHeader] = useState({
        en: {
            goalStatsHeader: [
                {name: 'FH +0.5 Goals', text: 'FH +0.5'},
                {name: '+1.5 Goals', text: '+1.5'},
                {name: '+2.5 Goals', text: '+2.5'},
                {name: 'BTTS', text: 'BTTS'},
            ],
            cornerStatsHeader: [
                {name: '+8.5 Corners', text: '+8.5'},
                {name: '+9.5 Corners', text: '+9.5'},
                {name: '+10.5 Corners', text: '+10.5'},
                {name: '+11.5 Corners', text: '+11.5'},
            ],
            cardsStatsHeader: [
                {name: '+3.5 Cards', text: '+3.5'},
                {name: '+4.5 Cards', text: '+4.5'},
                {name: '+5.5 Cards', text: '+5.5'},
                {name: '+6.5 Cards', text: '+6.5'},
            ],
            avgStatsHeader: [
                {name: 'Goals PG', text: 'Goals PG'},
                {name: 'Corners PG', text: 'Corners PG'},
                {name: 'Cards PG', text: 'Cards PG'},
            ],
            refereeStatsHeader: [
                {name: 'Yellows PG', text: 'Yellows PG'},
                {name: 'Reds PG', text: 'Reds PG'},
                {name: 'Pens PG', text: 'Pens PG'},
                {name: 'Fouls PG', text: 'Fouls PG'},
            ]
        },
        tr: {
            goalStatsHeader: [
                {name: 'FH +0.5 Goals', text: 'İY +0.5'},
                {name: '+1.5 Goals', text: '+1.5'},
                {name: '+2.5 Goals', text: '+2.5'},
                {name: 'BTTS', text: 'Karşılıklı Gol'},
            ],
            cornerStatsHeader: [
                {name: '+8.5 Corners', text: '+8.5'},
                {name: '+9.5 Corners', text: '+9.5'},
                {name: '+10.5 Corners', text: '+10.5'},
                {name: '+11.5 Corners', text: '+11.5'},
            ],
            cardsStatsHeader: [
                {name: '+3.5 Cards', text: '+3.5'},
                {name: '+4.5 Cards', text: '+4.5'},
                {name: '+5.5 Cards', text: '+5.5'},
                {name: '+6.5 Cards', text: '+6.5'},
            ],
            avgStatsHeader: [
                {name: 'Goals PG', text: 'Gol Ort.'},
                {name: 'Corners PG', text: 'Korner Ort.'},
                {name: 'Cards PG', text: 'Kart Ort.'},
            ],
            refereeStatsHeader: [
                {name: 'Yellows PG', text: 'Sarı K. Ort.'},
                {name: 'Reds PG', text: 'Kırmızı K. Ort.'},
                {name: 'Pens PG', text: 'Pen Ort.'},
                {name: 'Fouls PG', text: 'Faul Ort.'},
            ]
        }
    })

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <div className="fixtures-cards-container">
            <table className="fixtures-table-section stats-table-header">
                <tbody>
                <tr className="league-name-container">
                    {
                        <td className="league-name-and-flag-container">
                            {
                                favMatches && favMatches.length >= 0 ?
                                    <span className="table-12px-fav-header">Favorites</span> :
                                    <div
                                        onClick={() => {
                                            window.open(`/league/${league.title.area.toLowerCase()}-${league.title.league.toLowerCase().replace(/\s+/g, '-')}/${league.title.leagueId}/${league.title.seasonId}`)
                                        }}
                                        className={'league-name-and-flag'}
                                    >
                                        <img className="country-flag"
                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.title.areaId}.png`}
                                             onError={(e) => onImageError(e)}
                                             alt="country"/>
                                        <span
                                            className="area-info-container">{league.title.area} - {league.title.league}</span>
                                    </div>
                            }

                        </td>
                    }
                    {
                        statsHeader[lang][currentStats].map((stHeader) => {
                            return (
                                <StatsHeader currentStats={currentStats}  key={stHeader.text} stHeader={stHeader}/>
                            )
                        })
                    }
                </tr>
                </tbody>
            </table>
            <Matches favMatches={favMatches} league={league} matches={matches} statsType={statsType}></Matches>
        </div>
    );
}

export default LeagueAndMatchesTable;
