import React from 'react';
import dayjs from "dayjs";
import './MackolikMenu.css';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";

function LiveScoresMackolikMenu(props) {

    const {liveMatchButton, live, currentDate, setCurrentDate} = props;

    const clickDays = async (day) => {
        await setCurrentDate(dayjs(day.target.value).format('YYYY-MM-DD'))
    }

    const beforeDay = () => {
        const beforeDay = dayjs(currentDate).add(-1, 'days').format('YYYY-MM-DD');
        setCurrentDate(beforeDay);
    }
    const nextDay = () => {
        const beforeDay = dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD');
        setCurrentDate(beforeDay);
    }

    return (
        <div id="live-top-menu" sticky="" sticky-top="0" style={{zIndex: 1000}}>
            {/*<div className="live-score-bg" style={{borderBottom: 0}}>
                <div className="live-score-futbol2">
                    <div className="live-score-futbol2-l">
                        <a className="tick selected" id="chkSport1"
                           style={{float: 'left'}}></a>
                        <h2 className="futbol-text">Futbol</h2>

                        <div id="dvFootballMenu" className="lig-menu-content"
                             style={{display: 'none', zIndex: '1000000'}}>
                            <div id="dvFootballMenu0">
                                <div className="lig-menu-content-coll">

                                </div>

                                <div className="lig-menu-content-coll">

                                </div>

                                <div className="lig-menu-content-coll">


                                </div>

                                <div className="lig-menu-content-coll">


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="live-score-futbol2-r"></div>
                </div>
                <div className="live-score-basketbol2">
                    <div className="live-score-basketbol2-l">
                        <a className="tick selected" id="chkSport2"
                           style={{float: 'left'}}></a>
                        <h2 className="basketbol-text">Basketbol</h2>


                        <div id="dvBasketballMenu" className="lig-menu-content"
                             style={{display: 'none', width: '500px', zIndex: '1000000'}}>
                            <div className="lig-menu-content-coll">

                            </div>

                            <div className="lig-menu-content-coll">


                            </div>

                            <div className="lig-menu-content-coll">


                            </div>

                            <div className="lig-menu-content-coll">


                            </div>

                            <div className="lig-menu-content-coll">


                            </div>

                            <div className="lig-menu-content-coll">


                            </div>

                        </div>

                    </div>
                    <div className="live-score-basketbol2-r"></div>
                </div>
                <div className="date-text">Tarih :</div>
                <div className="select-temp">
                    <span className="date-left-coll" onClick={beforeDay} style={{margin: 0}}></span>
                    <div className="select-bg" style={{float: 'left'}}>
                        <input type="text" id="txtCalendar" className="textbox hasDatepicker"
                               style={{fontSize: '14px', width: '80px'}} onChange="gotoDate(0)" />
                    </div>
                    <a className="select-rightcoll" href="#"></a>
                    <div style={{position: 'relative'}}>
                        <input onChange={(value) => clickDays(value)} value={currentDate} type="date"/>
                    </div>
                    <span className="date-right-coll" onClick={nextDay} style={{margin: 0}}></span>
                </div>
            </div>
            <div style={{display: 'flex'}}>
                <div className="top-bar-stencil" style={{borderBottom: 0}}>
                    <div className="live-score-futbol">
                        <a className="show-type selected" id="aOrderByLeague">Lige göre</a>
                    </div>
                    <div className="live-score-basketbol">
                        <a className="show-type" id="aOrderByDate">Tarihe göre</a>
                    </div>
                    <a className="vol2" id="chkSelected">SEÇİLİ</a>
                    <a onClick={() => liveMatchButton(live)} className={live ? 'a vol2 selected' : 'vol2'} id="chkLive">CANLI</a>
                    <a className="vol2" id="chkIddaa" style={{color: '#e36a06'}}
                    >İDDAA</a>
                    <a className="vol2" id="chkLiveIddaa" style={{color: '#e36a06'}}
                    >
                        <img src="//im.mackolik.com/img5/icons/iddaa-nlive.png" alt='nlive'/>
                    </a>
                    <a className="vol" id="aSoundOn">
                        <img src="//im.mackolik.com/img5/wol.png" align="absmiddle" alt='wol'/>Ses Açık
                    </a>
                    <a className="vol" id="aSoundOff"
                       style={{display: 'none'}}>
                        <img src="//im.mackolik.com/img5/wol.png" align="absmiddle" alt='wol'/>Ses Kapalı
                    </a>
                </div>
                <div className="right-top-bar-bg" style={{width: '310px', float: 'right'}}>
                    <div className="live-menu-bar-t">
                        <div className="mc-top-bar-bg">
                            <a className="mc-top-bar-menu-active" id="extraMenu0"
                               style={{width: '50px', textAlign: 'center'}}>İddaa</a>
                            <div className="mc-top-bar-menu-border"></div>
                            <a className="mc-top-bar-menu" id="extraMenu1"
                               style={{width: '50px', textAlign: 'center'}}>Haber</a>
                            <div className="mc-top-bar-menu-border"></div>
                            <a className="mc-top-bar-menu" id="extraMenu2"
                               style={{width: '50px', textAlign: 'center'}}>Form</a>
                            <div className="mc-top-bar-menu-border"></div>
                            <a className="mc-top-bar-menu" id="extraMenu3"
                               style={{textAlign: 'center'}}>TV Prog.</a>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{clear: 'both'}}></div>*/}
        </div>
    );
}

export default LiveScoresMackolikMenu;
