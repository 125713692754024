import React from 'react';
import noTeamLogo from "../../../assets/icons/noTeamLogo.png";

function TeamInfo(props) {

    const {teamDetailsData} = props;

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    return (
        <div className='team-info-general-section'>
            <div className='team-info-general-container'>
                <div className='team-info-league-container'>

                    <div className='team-info-name-and-league-container'>
                        {/*<a className='team-info-league-name'>İSPANYA LaLiga > </a>*/}
                        <b className='team-info-team-name'>{teamDetailsData?.name}</b>
                    </div>

                    {/*<div className='team-info-season-container'>
                        <b className='team-info-season-text'>Sezon: </b>

                        <div className='team-info-season'>2023 / 2024</div>
                    </div>*/}
                </div>

                <div className='team-info-logo-and-name-container'>
                    <div className='team-logo-and-name-container'>
                        <img className="team-detail-team-logo"
                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${teamDetailsData?.id}.png`}
                             onError={(e) => onImageError(e)}
                             alt=""/>
                        <div className='team-info-team-and-country-texts'>
                            <h1 className='team-detail-team-name'>{teamDetailsData?.name}</h1>
                            <div className='team-detail-country-name-and-logo'>
                                {/*<img className="country-flag"
                                     src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.title.areaId}.png`}
                                     onError={(e) => onImageError(e)}
                                     alt="country"/>*/}
                                {/*<span className='team-detail-country-name'>İspanya</span>*/}
                            </div>
                        </div>
                    </div>

                    {/*<div className='coach-and-team-value-container'>
                        <table className='coach-and-team-value-table'>

                            <tbody>
                            <tr>
                                <td width={82}>T. Direktör</td>
                                <td>:</td>
                                <td><a className='team-info-coach-name'>Xavi</a></td>
                            </tr>

                            <tr>
                                <td width={82}>P.Değeri</td>
                                <td>:</td>
                                <td>949.250.000 EUR</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>*/}
                </div>

                <div>
                    {/*<div className="tab-container" style={{padding: '5px 15px'}}>
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="kulup-tbl"
                               style={{fontFamily: 'Arial', fontSize: '12px', color: '#999'}}>
                            <tbody>
                            <tr height="22">
                                <td width="95" className="club-font">Kuruluş Tarihi</td>
                                <td width="6" className="club-font">:</td>
                                <td width="170" className='club-font-2'>1899</td>
                                <td width="95" className="club-font">Stadyum</td>
                                <td width="6" className="club-font">:</td>
                                <td width="170">
                                    <a className='team-info-stadium'>
                                        Estadio Olímpico Lluís Companys
                                    </a>
                                </td>
                            </tr>
                            <tr height="22">
                                <td className="club-font">Web Sitesi</td>
                                <td className="club-font">:</td>
                                <td>
                                    <a className="club-font-normal" target="_blank"
                                       href="http:\\www.fcbarcelona.com ">www.fcbarcelona.com
                                    </a>
                                </td>
                                <td className="club-font">Kapasite</td>
                                <td className="club-font">:</td>
                                <td></td>
                            </tr>
                            <tr height="22">
                                <td className="club-font">Telefon</td>
                                <td className="club-font">:</td>
                                <td className='club-font-2'>93 496 36 00</td>
                                <td className="club-font">Fax</td>
                                <td className="club-font">:</td>
                                <td className='club-font-2'>93 411 22 19</td>
                            </tr>
                            <tr height="22">
                                <td className="club-font">Adres</td>
                                <td className="club-font">:</td>
                                <td className='club-font-2' colSpan="4">Avda. Arístides Maillol s/n, 08028 Barcelona</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>*/}
                </div>
            </div>
        </div>
    );
}

export default TeamInfo;
