import React from 'react';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import noTeamLogo from "../../../assets/icons/noTeamLogo.png";

function LeagueStandingsTableAll(props) {

    const {leagueStandingsData, lang} = props;
    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    return (
        <div className={'league-standings-table-section'}>
            <div className={'league-standings-table-container'}>
                <TableContainer>
                    <Table style={{width: '100%'}} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{background: '#e7e7e7'}}>
                                <TableCell align="right">#</TableCell>
                                <TableCell align="left">
                                    {
                                        lang === 'tr' ?
                                            <span>Takımlar</span>
                                            :
                                            <span>Teams</span>
                                    }
                                </TableCell>
                                <TableCell align="center">O</TableCell>
                                <TableCell align="center">G</TableCell>
                                <TableCell align="center">B</TableCell>
                                <TableCell align="center">M</TableCell>
                                <TableCell align="center">A</TableCell>
                                <TableCell align="center">Y</TableCell>
                                <TableCell align="center">Av</TableCell>
                                <TableCell align="center">P</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leagueStandingsData &&
                                leagueStandingsData.map((row) => (
                                    <TableRow
                                        key={row.name}
                                    >
                                        <TableCell align="right">{row.position}</TableCell>
                                        <TableCell className={'standings-table-team-container'} align="left">
                                            <img className="standings-team-logo"
                                                 src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${row.teamId}.png`}
                                                 onError={(e) => onImageError(e)}
                                                 alt=""/>
                                            <span className={'standings-team-name'}>{row.teamName}</span>
                                        </TableCell>
                                        <TableCell align="center">{row.played}</TableCell>
                                        <TableCell align="center">{row.win}</TableCell>
                                        <TableCell align="center">{row.draw}</TableCell>
                                        <TableCell align="center">{row.loss}</TableCell>
                                        <TableCell align="center">{row.goalsFor}</TableCell>
                                        <TableCell align="center">{row.goalsAgainst}</TableCell>
                                        <TableCell align="center">{row.goalsDiff}</TableCell>
                                        <TableCell align="center">{row.points}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default LeagueStandingsTableAll;
