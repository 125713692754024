import React, {useContext, useEffect, useState} from 'react';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import GoalOverallTable from "./goal-stats-table/GoalOverallTable";
import {useParams} from "react-router-dom";
import {fetchLeagueDetail} from "../../../api";
import GoalHomeTable from "./goal-stats-table/GoalHomeTable";
import GoalAwayTable from "./goal-stats-table/GoalAwayTable";
import Gaol1HalfTable from "./goal-stats-table/Gaol1halfTable";
import Goal2HalfTable from "./goal-stats-table/Goal2HalfTable";
import GoalForTable from "./goal-stats-table/GoalForTable";
import GoalAgainstTable from "./goal-stats-table/GoalAgainstTable";
import CornerOverallTable from "./corner-stats-table/CornerOverallTable";
import CornerHomeTable from "./corner-stats-table/CornerHomeTable";
import CornerAwayTable from "./corner-stats-table/CornerAwayTable";
import Corner1HalfTable from "./corner-stats-table/Corner1HalfTable";
import Corner2HalfTable from "./corner-stats-table/Corner2HalfTable";
import CornerForTable from "./corner-stats-table/CornerForTable";
import CornerAgainstTable from "./corner-stats-table/CornerAgainstTable";
import CardOverallTable from "./card-stats-table/CardOverallTable";
import CardHomeTable from "./card-stats-table/CardHomeTable";
import CardAwayTable from "./card-stats-table/CardAwayTable";
import Card1HalfTable from "./card-stats-table/Card1HalfTable";
import Card2HalfTable from "./card-stats-table/Card2HalfTable";
import CardForTable from "./card-stats-table/CardForTable";
import CardAgainstTable from "./card-stats-table/CardAgainstTable";
import {AppContext} from "../../../context/AppContext";

function LeagueStats(props) {
    const {lang} = useContext(AppContext);

    const {league} = props;

    const [selectedType, setSelectedType] = useState("goals");
    const [toggle, setToggle] = useState(0);


    const onClickTabsType = (tabType) => {
        setSelectedType(tabType.type);
        setToggle(tabType.toggle)
    }

    const statsTabsType = [
        {
            en: [
                {
                    tabsName: 'Goals',
                    type: 'goals',
                    toggle: 0
                },
                {
                    tabsName: 'Corners',
                    type: 'corners',
                    toggle: 1
                },
                {
                    tabsName: 'Cards',
                    type: 'cards',
                    toggle: 2
                },
            ],
            tr: [
                {
                    tabsName: 'Gol',
                    type: 'goals',
                    toggle: 0
                },
                {
                    tabsName: 'Korner',
                    type: 'corners',
                    toggle: 1
                },
                {
                    tabsName: 'Kart',
                    type: 'cards',
                    toggle: 2
                },
            ]
        }
    ]
    const subTabIndex = statsTabsType[0][lang].findIndex((st) => st.type === selectedType)

    return (
        <div className={'league-stats-section'}>
            <div className={'league-stats-type-tabs-container'}>
                <Tabs
                    className={'league-detail-stats-container'}
                    value={subTabIndex}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#00b1ff"
                        }
                    }}
                >
                    {
                        statsTabsType[0][lang].map((tabTitle, index) => {
                            return (<Tab
                                key={tabTitle.type}
                                className={'league-details-tab-item'}
                                onClick={() => onClickTabsType(tabTitle)}
                                value={index}
                                label={tabTitle.tabsName}
                            />)
                        })
                    }
                </Tabs>
            </div>

            {
                !!league.seasonId &&
                <div>
                    {
                        toggle === 0 &&
                        <div>
                            <GoalOverallTable lang={lang} league={league} type={'GoalsOverall'}/>
                            <GoalHomeTable lang={lang} league={league} type={'GoalsHome'}/>
                            <GoalAwayTable lang={lang} league={league} type={'GoalsAway'}/>
                            <Gaol1HalfTable lang={lang} league={league} type={'Goals1Half'}/>
                            <Goal2HalfTable lang={lang} league={league} type={'Goals2Half'}/>
                            <GoalForTable lang={lang} league={league} type={'GoalsFor'}/>
                            <GoalAgainstTable lang={lang} league={league} type={'GoalsAgainst'}/>
                        </div>
                    }
                    {
                        toggle === 1 &&
                        <div>
                            <CornerOverallTable lang={lang} league={league} type={'CornersOverall'}/>
                            <CornerHomeTable lang={lang} league={league} type={'CornersHome'}/>
                            <CornerAwayTable lang={lang} league={league} type={'CornersAway'}/>
                            <Corner1HalfTable lang={lang} league={league} type={'Corners1Half'}/>
                            <Corner2HalfTable lang={lang} league={league} type={'Corners2Half'}/>
                            <CornerForTable lang={lang} league={league} type={'CornersFor'}/>
                            <CornerAgainstTable lang={lang} league={league} type={'CornersAgainst'}/>
                        </div>
                    }
                    {
                        toggle === 2 &&
                        <div>
                            <CardOverallTable lang={lang} league={league} type={'CardsOverall'}/>
                            <CardHomeTable lang={lang} league={league} type={'CardsHome'}/>
                            <CardAwayTable lang={lang} league={league} type={'CardsAway'}/>
                            <Card1HalfTable lang={lang} league={league} type={'Cards1Half'}/>
                            <Card2HalfTable lang={lang} league={league} type={'Cards2Half'}/>
                            {/*<CardForTable league={league} type={'CardsFor'}/>
                    <CardAgainstTable league={league} type={'CardsAgainst'}/>*/}
                        </div>
                    }
                </div>
            }

        </div>
    );
}

export default LeagueStats;
