import React, {Component, useContext, useEffect, useState} from 'react';
import TopMenus from "./TopMenus";
import './Header.css'
import '../../App.css'
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import img from '../../assets/icons/instagram.png'
import img1 from '../../assets/icons/facebook.png'
import img2 from '../../assets/icons/twitter.png'
import fl from '../../assets/flags/TR.png'
import fl1 from '../../assets/flags/EN.png'
import fl2 from '../../assets/flags/DE.png'
import fl3 from '../../assets/flags/FR.png'
import fl4 from '../../assets/flags/ES.png'
import user from '../../assets/icons/user.png'
import logo from '../../assets/icons/logo.png'
import tvIcon from '../../assets/icons/tv-icon.png'
import {slide as Menu} from 'react-burger-menu'
import Button from "@mui/material/Button";
import {AppContext} from "../../context/AppContext";

function Header(props) {
    const {logOut, setLanguage, isPro, lang} = useContext(AppContext);
    const userToken = localStorage.getItem('user-token');
    const username = localStorage.getItem('username');
    const navigate = useNavigate();
    const userDetail = localStorage.getItem('user');
    const user = JSON.parse(userDetail);
    const [userInfo, setUserInfo] = useState(null);

    const activeLanguage = (lan) => {
        if (localStorage.getItem('language') === lan) {
            return {
                width: '24px',
                height: '24px'
            }
        }
    }

    const currentRoutes = useLocation();

    const pathName = currentRoutes.pathname;
    const joinPremiumButton = () => {
        if (pathName !== '/sign-in' && pathName !== '/sign-up') {
            if (!user) {
                return (
                    <div className='fixed-join-premium-container'
                         onClick={() => toPremium()}
                    >
                        <span className='fixed-join-premium-text'>Join to XFStats Premium</span>
                    </div>
                )
            } else if (user.premium === null || user.premium.status !== 'active') {
                return (
                    <div className='fixed-join-premium-container'
                         onClick={() => toPremium()}
                    >
                        <span className='fixed-join-premium-text'>Join to XFStats Premium</span>
                    </div>
                )
            }
        }
    }

    const toPremium = () => {
        navigate('/join-premium');
    }

    const location = useLocation();
    const [count, setCount] = useState(0);
    const isLmtOn = pathName.includes('/fv-lmt-v3');

    useEffect(() => {
        // execute on location change
        setCount(count + 1);
    }, [location]);

    return (
        <div className={isLmtOn ? 'isLmtOn' : 'isLmtOff'}>
            {
                location.pathname === '/join-premium' &&
                <div className="join-premium-header-container">
                    <div>
                        <Link to={'/'} className="site-logo-text">
                            <img className={'logo'} src={logo} alt="user"/>
                        </Link>
                    </div>
                </div>
            }
            {
                location.pathname !== `/join-premium` &&
                <div>
                    {joinPremiumButton()}

                    <div className="mid-header-section">
                        {/*FOR MOBILE*/}
                        <div className={'hamburger-menu-section'}>
                            <Menu>
                                <a href={'/'} className="burger-menu-site-logo-text">
                                    <img className={'logo'} src={logo} alt="user"/>
                                </a>
                                <div className='authentication-section'>
                                    {
                                        userToken ?
                                            <div className={'user-info-container'}>
                                                <div className={'user-detail-container burger-menu-content'}>
                                                    <img className={'user-icon'} src={user} alt="user"/>
                                                    <div className={'username-text'}>{username}</div>
                                                </div>
                                                <div className={'mobile-exit-button-container burger-menu-content'}
                                                     style={{alignItems: 'self-start'}}>
                                                    <Button variant="text" onClick={logOut}><span
                                                        className={'mobile-exit-button'}>
                                                        {
                                                            lang === 'tr' ?
                                                                <span>Çıkış</span>
                                                                :
                                                                <span>Logout</span>
                                                        }
                                                    </span></Button>
                                                </div>
                                            </div>
                                            :
                                            <div className={'mobile-auth-burger-menu-container'}>
                                                <a id="home" className="burger-menu-item burger-menu-content"
                                                   href="/sign-in">SIGN
                                                    IN</a>
                                                <a id="about" className="burger-menu-item burger-menu-content"
                                                   href="/sign-up">SIGN
                                                    UP</a>
                                            </div>
                                    }
                                </div>

                                <div className='flags-for-language-section-mobile'>
                                    <img style={activeLanguage('tr')} onClick={() => setLanguage('tr')}
                                         className='language-flags-mobile' src={fl} alt="Türkiye"/>
                                    <img style={activeLanguage('en')} onClick={() => setLanguage('en')}
                                         className='language-flags-mobile' src={fl1} alt="England"/>
                                </div>
                            </Menu>
                        </div>
                        <div className="const-width">

                            <div className="logo-container">
                                <Link to={'/'} className="site-logo-text">
                                    <img className={'logo'} src={logo} alt="user"/>
                                </Link>

                                <Link to={'/tv'} className='tv-icon-container'>
                                    <img style={{
                                        background: 'white',
                                        width: '25px',
                                        height: '20px',
                                        padding: '3px',
                                        borderRadius: '5px'
                                    }} src={tvIcon} alt="user"/>
                                </Link>

                                <div className='flags-for-language-section'>
                                    <img style={activeLanguage('tr')} onClick={() => setLanguage('tr')}
                                         className='language-flags' src={fl} alt="Türkiye"/>
                                    <img style={activeLanguage('en')} onClick={() => setLanguage('en')}
                                         className='language-flags' src={fl1} alt="England"/>
                                </div>

                                {
                                    userToken ?
                                        <div className={'exit-button-container'}>
                                            <div className={'user-name-container'}>
                                                <Link className={'username-text-web'}
                                                      to={`/account-detail/${username}`}>{username}</Link>
                                            </div>
                                            <Button variant="outlined" onClick={logOut}>
                                                {
                                                    lang === 'tr' ?
                                                        <span>Çıkış</span>
                                                        :
                                                        <span>Logout</span>
                                                }
                                            </Button>
                                        </div>
                                        :
                                        <div className={'sign-in-sign-up-button-section'}>
                                            <div className={'sign-in-button-container'}>
                                                {
                                                    lang === 'tr' ?
                                                        <Link to={'sign-in'} className={'sign-in-text'}>Giriş Yap</Link>
                                                        :
                                                        <Link to={'sign-in'} className={'sign-in-text'}>SIGN IN</Link>
                                                }
                                            </div>

                                            <div className={'sign-up-button-container'}>
                                                {
                                                    lang === 'tr' ?
                                                        <Link to={'sign-up'} className={'sign-up-text'}>Kayıt Ol</Link>
                                                        :
                                                        <Link to={'sign-up'} className={'sign-up-text'}>SIGN UP</Link>
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="top-navigation-bar">
                        <div className="const-width">
                            <TopMenus></TopMenus>
                        </div>
                    </div>
                    <Outlet/>
                </div>
            }
        </div>
    );
}

export default Header;
