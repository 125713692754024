export const colorTheme = {
    selectedTeam: '#CC6633',
    normalTeam: '#0C3A7E',
    selectedTableStatsHeader: 'rgb(209 209 209)',
    selectedTableStats: '#8d8d8d',
    dark444: '#444',
    dark333: '#333',
    matchTime: '#D90000',
    liveMatchResult: '#dd0000',
}

