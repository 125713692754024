import './App.css';
import Header from "./components/header/Header";
import Application from "./components/Application";
import {AppProvider} from "./context/AppContext";

function App() {
  return (
    <div className="App">
        <AppProvider>
            <Application></Application>
        </AppProvider>
    </div>
  );
}

export default App;
